import * as Sentry from '@sentry/browser';
import api from 'api';
import store from 'store';

import IAsync from 'types/IAsync';
import IProject from 'types/IProject';

function setProjectsAsync(projectsAsync: IAsync<IProject[]>) {
  store.setState((state) => ({
    ...state,
    projectsAsync,
  }));
}

export default function fetchProjects() {
  const async = store.getState().projectsAsync;

  switch (async.status) {
    case 'PENDING':
    case 'REFRESH':
      // Already fetching
      return;
    case 'INIT':
    case 'ERROR':
      setProjectsAsync({ status: 'PENDING' });
      break;
    case 'SUCCESS':
      // Only fetch this once
      return;
  }

  api
    .get('projects')
    .json()
    .then(
      (data) => {
        setProjectsAsync({ status: 'SUCCESS', data: data as IProject[] });
      },
      (error) => {
        Sentry.captureException(error);
        setProjectsAsync({ status: 'ERROR' });
      }
    );
}
