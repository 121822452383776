import * as Sentry from '@sentry/browser';
import api from 'api';
import store from 'store';

import getProjectId from 'utils/url/getProjectId';
import getProjectState from 'store/getProjectState';
import setProjectState from 'store/setProjectState';

import { IProjectState } from 'store';
import IAsync from 'types/IAsync';
import IUser from 'types/IUser';

function setUsersAsync(projectId: string, usersAsync: IAsync<IUser[]>) {
  setProjectState(projectId, (state: IProjectState) => {
    return {
      ...state,
      usersAsync,
    };
  });
}

export default function fetchUsers() {
  const projectId = getProjectId();

  const async = getProjectState(store.getState(), projectId).usersAsync;

  switch (async.status) {
    case 'PENDING':
    case 'REFRESH':
      // Already fetching
      return;
    case 'INIT':
    case 'ERROR':
      setUsersAsync(projectId, { status: 'PENDING' });
      break;
    case 'SUCCESS':
      // Only fetch this once
      return;
  }

  api
    .get(`projects/${projectId}/users`)
    .json()
    .then(
      (data) => {
        setUsersAsync(projectId, {
          status: 'SUCCESS',
          data: data as IUser[],
        });
      },
      (error) => {
        Sentry.captureException(error);
        setUsersAsync(projectId, { status: 'ERROR' });
      }
    );
}
