import { useEffect, useCallback } from 'react';
import { useStore } from 'store';

import fetchRunFiles from 'store/fetchRunFiles';
import getCurrentProjectState from 'store/getCurrentProjectState';

import IAsync from 'types/IAsync';
import IRunFile from 'types/IRunFile';

const initAsync = { status: 'INIT' };

export default function useAutofetchedRunFilesAsync(
  runId: string
): IAsync<IRunFile[]> {
  const runFilesAsync = useStore(
    useCallback(
      (state) => getCurrentProjectState(state).runFilesAsyncMap[runId],
      [runId]
    )
  );

  useEffect(() => {
    fetchRunFiles(runId);
  }, [runId]);

  return runFilesAsync || initAsync;
}
