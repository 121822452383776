/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import Title from 'components/Title/Title';
import Page from 'components/Page/Page';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import ErrorScreen from 'components/ErrorScreen/ErrorScreen';

import StateBadge from './StateBadge/StateBadge';
import TaskGraph from './TaskGraph/TaskGraph';
import ExpandableDetails from './ExpandableDetails/ExpandableDetails';
import JsonViewer from './JsonViewer/JsonViewer';
import TaskDetails from './TaskDetails/TaskDetails';

import Paper from '@material-ui/core/Paper';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAutofetchedRunDetailsAsync from 'store/useAutofetchedRunDetailsAsync';

import { unique } from 'utils/array';

import { supportedRunStates } from 'types/RunState';

export default function WorkflowRunDetailsPage() {
  const { runId } = useParams();
  const runDetailsAsync = useAutofetchedRunDetailsAsync(runId);

  const [expandWorkflowDetails, setExpandWorkflowDetails] = useState(true);
  const [selectedTaskIds, setSelectedTaskIds] = useState<string[]>([]);

  useEffect(() => {
    setExpandWorkflowDetails(selectedTaskIds.length === 0);
  }, [selectedTaskIds]);

  const [columnMode, setColumnMode] = useState<'DAG' | 'DETAILS' | 'BOTH'>(
    'BOTH'
  );

  switch (runDetailsAsync.status) {
    case 'INIT':
    case 'PENDING':
      return <LoadingScreen />;
    case 'ERROR':
      return <ErrorScreen />;
    case 'SUCCESS':
    case 'REFRESH':
      const runDetails = runDetailsAsync.data;

      const usedRunStates = Object.values(runDetails.task_states)
        .filter(unique)
        .sort(
          (a, b) =>
            supportedRunStates.indexOf(a) - supportedRunStates.indexOf(b)
        );

      return (
        <Fragment>
          <Title>{`${runDetails.name} | Tailor`}</Title>

          <Page>
            <div
              css={css`
                margin: 48px;
                margin-top: 24px;

                display: flex;
                flex-direction: column;
                flex-grow: 1;
              `}
            >
              <div
                css={css`
                  display: flex;
                `}
              >
                <ToggleButtonGroup
                  exclusive
                  value={columnMode}
                  onChange={(e, mode) =>
                    // Don't change mode to null (we want exactly one selected at all times)
                    mode && setColumnMode(mode)
                  }
                  aria-label="View mode"
                  css={css`
                    button {
                      padding: 4px 24px;
                    }
                  `}
                >
                  <ToggleButton
                    value="DAG"
                    aria-label="Show only DAG"
                    title="Show only DAG"
                  >
                    DAG
                  </ToggleButton>
                  <ToggleButton
                    value="BOTH"
                    aria-label="Show both DAG and Details"
                    title="Show both DAG and Details"
                  >
                    Both
                  </ToggleButton>
                  <ToggleButton
                    value="DETAILS"
                    aria-label="Show only Details"
                    title="Show only Details"
                  >
                    Details
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div
                css={css`
                  margin-top: 24px;

                  @media (max-width: 767px) {
                    margin: 12px;
                  }

                  flex-grow: 1;
                  display: flex;
                  grid-gap: 24px;

                  > *:first-of-type {
                    // Make graph column sticky, and prevent it from growing beyond
                    // filling the space between header and footer.
                    max-height: calc(100vh - 276px);
                    overflow: auto;
                    position: sticky;
                    top: 147px;
                  }

                  > *:nth-of-type(2) {
                    width: 0; // Limit flex-grow
                  }

                  > * {
                    // Control width of the two columns (max 100vw - vertical margins)
                    min-width: min(calc(100vw - 2 * 48px), 580px);
                    // Let containers grow (only first container should grow unless
                    // actually grows unless 2nd one gets wrapped onto a 2nd row)
                    flex-grow: 1;
                  }

                  @media (max-width: 1280px /* 1280 = 2*580 + 2*48 + 24 – Max width with 2 columns */) {
                    flex-direction: column;

                    > *:first-of-type {
                      position: unset;
                      max-height: unset;
                    }
                    > *:nth-of-type(2) {
                      width: unset;
                    }
                  }
                `}
              >
                {['DAG', 'BOTH'].includes(columnMode) && (
                  <Paper
                    elevation={2}
                    css={css`
                      flex-grow: 1;
                      display: flex;
                      flex-direction: column;
                    `}
                  >
                    <TaskGraph
                      runDetails={runDetails}
                      setSelectedTaskIds={setSelectedTaskIds}
                    />
                    <div
                      css={css`
                        padding: 24px;
                        display: flex;
                        flex-wrap: wrap;
                        grid-gap: 12px;
                      `}
                    >
                      {usedRunStates.map((state) => (
                        <StateBadge key={state} state={state} />
                      ))}
                    </div>
                  </Paper>
                )}

                {['DETAILS', 'BOTH'].includes(columnMode) && (
                  <div>
                    <Accordion
                      expanded={expandWorkflowDetails}
                      onChange={() => setExpandWorkflowDetails((on) => !on)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="run-details-content"
                        id="run-details-header"
                      >
                        <div
                          css={css`
                            padding: 0 8px 0 12px;
                            flex-grow: 1;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                          `}
                        >
                          <Typography component="h1" variant="h6">
                            {runDetails.id} {runDetails.name}
                          </Typography>
                          <Typography
                            component="h1"
                            variant="h6"
                            style={{ fontSize: 10, letterSpacing: 1.5 }}
                          >
                            WORKFLOW
                          </Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails style={{ display: 'block' }}>
                        <ExpandableDetails
                          summary="Inputs"
                          contentPlaceholder="Inputs as json (only rendered when open)"
                          renderContent={() => (
                            <JsonViewer json={runDetails.inputs} />
                          )}
                        />
                        <ExpandableDetails
                          summary="Outputs"
                          contentPlaceholder="Outputs as json (only rendered when open)"
                          renderContent={() => (
                            <JsonViewer json={runDetails.outputs} />
                          )}
                        />
                        <ExpandableDetails
                          summary="Files"
                          contentPlaceholder="Files as json (only rendered when open)"
                          renderContent={() => (
                            <JsonViewer json={runDetails.files} />
                          )}
                        />
                        <ExpandableDetails
                          summary="DAG"
                          contentPlaceholder="DAG as json (only rendered when open)"
                          renderContent={() => (
                            <JsonViewer json={runDetails.dag} />
                          )}
                        />
                      </AccordionDetails>
                    </Accordion>

                    {selectedTaskIds.map((taskId) => (
                      <TaskDetails key={taskId} runId={runId} taskId={taskId} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </Page>
        </Fragment>
      );
  }
}
