import { useStore, State } from 'store';

import getCurrentProjectState from 'store/getCurrentProjectState';

import IUser from 'types/IUser';

const selector = (state: State) =>
  getCurrentProjectState(state).usersAsync.data;

export default function useUsers(): void | IUser[] {
  return useStore(selector);
}
