import store from 'store';
import { IStartWorkflowState } from './state';

export default function setStartWorkflowState(
  startWorkflow: IStartWorkflowState
) {
  store.setState({
    startWorkflow,
  });
}
