import { State } from 'store';
import getCurrentProjectState from 'store/getCurrentProjectState';

import IWorkflowDefinitionDetails from 'types/IWorkflowDefinitionDetails';

export default function getSelectedWorkflowDefinitionDetails(
  state: State
): IWorkflowDefinitionDetails | void {
  const defnition =
    state.startWorkflow.steps.selectWorkflow.selectedWorkflowDefinition;

  if (!defnition) {
    return;
  }

  const detailsAsync = getCurrentProjectState(state)
    .workflowDefinitionDetailsAsyncMap[defnition.id];

  if (!detailsAsync) {
    return;
  }

  return detailsAsync.data;
}
