import { useState } from 'react';
import * as Sentry from '@sentry/browser';
import api from 'api';

import IAsync from 'types/IAsync';

interface IResponse {
  api_worker_id: string;
  api_secret_key: string;
}

export default function useGenerateToken() {
  const [tokenAsync, setTokenAsync] = useState<IAsync<string>>({
    status: 'INIT',
  });

  function generateToken({
    userId,
    accountId,
  }: {
    userId: string;
    accountId: string;
  }) {
    if (tokenAsync.status === 'PENDING') {
      return;
    }

    setTokenAsync({ status: 'PENDING' });

    api
      .post(`accounts/${accountId}/users/${userId}/apikey`)
      .json()
      .then(
        (data) => {
          console.log(`data`, data); // DEBUG
          setTokenAsync({
            status: 'SUCCESS',
            data: (data as IResponse).api_secret_key,
          });
        },
        (error) => {
          Sentry.captureException(error);
          setTokenAsync({ status: 'ERROR' });
        }
      );
  }

  return { tokenAsync, generateToken };
}
