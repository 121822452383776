/**
 * # Notes
 *
 * A few tips about how cytoscape works, as unfortunately it's not that
 * easy to learn from the documentation.
 *
 *
 * ## Data and labels
 *
 * The elements property sent to cytoscape({ elements, ... }) is an
 * array of nodes and edges.
 *
 * Nodes and edges shall have a data property, where you can add any
 * metadata you want (and they must have an id). E.g.
 *
 * ```
 * {
 *   data: {
 *     id: 'unique id referenced by edges',
 *     name: 'some value',
 *   }
 * }
 * ```
 *
 * Then node.data.name will be accessible in the stylesheet as:
 *
 * node { // styling for nodes
 *   // Label is the text shown on the node
 *   label: data(name); // Show node.data.name on each node
 * }
 *
 *
 * ## Class-names and conditionaly styling
 *
 * You can also add class-names to style nodes conditionally:
 *
 * ```
 * {
 *   data: { ... }
 *   classes: ['example'],
 * }
 * ```
 *
 * which can be styled in the stylesheet:
 *
 * node.example {
 *   // custom style
 * }
 */

export default `
  node {
    content: data(name);
    width: data(width);

    shape: rectangle;

    /* style text */
    font-family: monospace;
    font-size: 15;

    text-valign: center;

    color: white;
    text-outline-color: #888;
    text-outline-width: 1;
  }

  node.ready {
    background-color: #2e92f2;
  }
  node.waiting {
    background-color: #1f62a2;
  }
  node.running {
    background-color: #f4b90b;
  }
  node.failed {
    background-color: #db0051;
  }
  node.completed {
    background-color: #24c75a;
  }
  node.reserved {
    background-color: #bb8bc1;
  }
  node.archived {
    background-color: #7f8287;
  }
  node.stopped {
    background-color: #b7bcc3;
  }
  node.paused {
    background-color: #ffcfca;
  }

  node:selected {
    border-width: 3;
    border-color: black;
  }

  edge {
    width: 3px;
    target-arrow-shape: triangle;
    curve-style: bezier;

    line-color: #ddd;
    target-arrow-color: #ddd;
  }

  .faded {
    opacity: 0.25;
    text-opacity: 0;
  }
`;
