import React, { useMemo } from 'react';
import Editor from '@monaco-editor/react';

export default function JsonViewer({ json }: { json: {} }) {
  const jsonString = useMemo(() => JSON.stringify(json, null, 2), [json]);

  const heightPx = useMemo(() => {
    const nofLines = jsonString.split('\n').length;
    return 20 + 20 * nofLines;
  }, [jsonString]);

  return (
    <Editor
      height={`${heightPx}px`}
      theme="vs-light"
      language="json"
      value={jsonString}
      options={{
        readOnly: true,
      }}
      loading={'Loading...'}
    />
  );
}
