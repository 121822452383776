import { useMemo } from 'react';
import useFileMap from './useFileMap';
import useFilesSchema from './useFilesSchema';

import isFileMapValid from './isFileMapValid';

export default function useAreFilesValid() {
  const fileMap = useFileMap();
  const schema = useFilesSchema();

  return useMemo(() => {
    if (!schema) {
      return false;
    }
    return isFileMapValid(schema, fileMap);
  }, [fileMap, schema]);
}
