import store from 'store';
import getProjectId from 'utils/url/getProjectId';
import getProjectState from 'store/getProjectState';

import { format } from 'date-fns';
import en from 'date-fns/locale/en-US';

function getTimestamp() {
  return format(new Date(), '📅_yyyy-MM-dd 🕜_HH-mm-ss', { locale: en });
}

function getProjectName() {
  const projectId = getProjectId();
  const projects = store.getState().projectsAsync.data;

  if (!projects) {
    // Won't happen
    return '';
  }

  const project = projects.find((project) => project.id === projectId);

  if (!project) {
    // Won't happen
    return '';
  }

  return project.name;
}

function getRunName(runId: string): string {
  const runs = getProjectState(store.getState(), getProjectId()).runsAsync.data;

  if (!runs) {
    // Won't happen
    return '';
  }

  const run = runs.find((run) => run.id === runId);

  if (!run) {
    // Won't happen
    return '';
  }

  return run.name;
}

export default function createZipFilename(runId: string) {
  return `${getRunName(runId)} – ${getProjectName()} – ${getTimestamp()}`;
}
