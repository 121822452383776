import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';

import zipAndDownload from 'shared/ZipProgresses/zipAndDownload';
import createZipFilename from './createZipFilename';

import IRunFile from 'types/IRunFile';

export default function DownloadButton({
  selectedFiles,
  runId,
}: {
  selectedFiles: IRunFile[];
  runId: string;
}) {
  const [alreadyDownloaded, setAlreadyDownloaded] = useState(false);

  useEffect(() => {
    // Enable downloading again if file selection changes
    setAlreadyDownloaded(false);
  }, [selectedFiles]);

  function downloadFiles() {
    setAlreadyDownloaded(true);
    zipAndDownload(selectedFiles, createZipFilename(runId));
  }

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={selectedFiles.length === 0 || alreadyDownloaded}
      onClick={downloadFiles}
    >
      Download selected files ({selectedFiles.length}) as zip
    </Button>
  );
}
