import { State, IProjectState } from 'store';

const initialProjectState = {
  usersAsync: { status: 'INIT' },
  runsAsync: { status: 'INIT' },
  runDetailsAsyncMap: {},
  runFilesAsyncMap: {},
  taskDetailsAsyncMap: {},
  workflowDefinitionsAsync: { status: 'INIT' },
  workflowDefinitionDetailsAsyncMap: { status: 'INIT' },
};

export default function getProjectState(
  state: State,
  projectId: string
): IProjectState {
  return state.projectStateMap[projectId] || initialProjectState;
}
