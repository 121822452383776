import React, { useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';

interface Props {
  onClickOutside: () => void;
  children: React.ReactElement<any>;
}

export default function ClickOutside(props: Props) {
  const [node, setNode] = useState<null | HTMLElement>(null);
  useClickOutside(node, props.onClickOutside);

  return React.cloneElement(props.children, {
    ref: setNode,
  });
}
