import { useEffect } from 'react';

export default function usePreventUnload(shouldPreventUnload: boolean) {
  useEffect(() => {
    function callback(event: any) {
      event.preventDefault();
      event.returnValue = '';
      return '';
    }
    if (shouldPreventUnload) {
      window.addEventListener('beforeunload', callback);
      return () => window.removeEventListener('beforeunload', callback);
    }
  }, [shouldPreventUnload]);
}
