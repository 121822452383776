import React from 'react';
import * as S from './LandingPageLoggedOut.style';
import { Link as RouterLink } from 'react-router-dom';
import tailorLogo from 'logos/tailor.svg';
import { Button, Typography } from '@material-ui/core';
import * as Feature from './Features';

import { Auth } from 'aws-amplify';

export default function LandingPageLoggedOut() {
  return (
    <S.Page>
      <div className="wrapper-restrict-width">
        <header>
          <RouterLink to="/">
            <img className="tailor" alt="Tailor" src={tailorLogo} />
          </RouterLink>
          <Button
            variant="outlined"
            style={{
              borderColor: '#fff',
              color: '#fff',
              minWidth: 96,
              minHeight: 36,
            }}
            onClick={() => Auth.federatedSignIn()}
          >
            Log in
          </Button>
        </header>
        <main>
          <Typography component="h1" variant="h3">
            Where engineers go to find, create and run workflows
          </Typography>
          <Button
            className="request-access"
            component="a"
            href="https://docs.google.com/forms/d/e/1FAIpQLSd5gTYakALzVqC4Bo6lkn7PV-UsnlsO1yDsiQmgh-q-8RkqNQ/viewform?vc=0&c=0&w=1&flr=0"
            variant="contained"
            style={{
              minHeight: 56,
              background: 'var(--mint)',
              color: 'var(--ultraviolet)',
              letterSpacing: 1.25,
              boxShadow: 'none',
            }}
          >
            Request early access
          </Button>
          <div className="features">
            <Feature.Cloud />
            <Feature.Python />
            <Feature.App />
            <Feature.Database />
          </div>
          <div className="resources">
            <article className="item">
              <Typography component="h2" variant="h4">
                Tailor Workflow Gallery
              </Typography>
              <Typography variant="body1">
                We at Entail are continuously developing new workflows for
                parallelization of simulations for a range of engineering
                software, automatic file handling and reporting.
              </Typography>
              <Typography variant="body1">
                Check it out{' '}
                <a href="https://entailor.github.io/tailor-workflow-gallery/">
                  here
                </a>
                .
              </Typography>
            </article>
            <article className="item">
              <Typography component="h2" variant="h4">
                pyTailor
              </Typography>
              <Typography variant="body1">
                pyTailor is our Python client for developing and setting up new
                workflow definitions.
              </Typography>
              <Typography variant="body1">
                See the{' '}
                <a href="https://entailor.github.io/pytailor/">
                  API documentation
                </a>
                .
              </Typography>
            </article>
          </div>
        </main>
        <footer>
          <p>
            ©2020 <a href="https://www.entail.no">Entail AS</a>. By engineers,
            for engineers. All rights reserved.
          </p>
        </footer>
      </div>
    </S.Page>
  );
}
