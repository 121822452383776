import IAsync from 'types/IAsync';

export default function postAsync<T>({
  getAsync,
  setAsync,
  post,
}: {
  getAsync: () => IAsync<T>;
  setAsync: (genericAsync: IAsync<T>) => void;
  post: () => Promise<T>;
}) {
  const genericAsync: IAsync<T> = getAsync();

  if (genericAsync.status === 'SUCCESS') {
    return Promise.resolve(genericAsync);
  }

  setAsync({ status: 'PENDING' });

  return post().then(
    (response) => {
      const genericAsync = { status: 'SUCCESS', data: response };
      setAsync(genericAsync as IAsync<T>);
      return genericAsync;
    },
    (error) => {
      setAsync({ status: 'ERROR' });
      throw error;
    }
  );
}
