import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

export default function AlertIncomplete() {
  return (
    <Alert severity="info">
      <AlertTitle>Incomplete</AlertTitle>
      Provided inputs are incomplete or invalid.
    </Alert>
  );
}
