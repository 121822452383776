/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import {
  Link as MuiLink,
  Button,
  TextField,
  LinearProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import useSelectedFileMap from '../pageState/steps/useSelectedFileMap';
import useSubmitState from '../pageState/submit/useSubmitState';
import setRunName from '../pageState/submit/setRunName';
import submitWorkflow from '../pageState/submit/submitWorkflow';

import { ReactNode } from 'react';
import ISystemFile from 'types/ISystemFile';
import IRunDetails from 'types/IRunDetails';

export default function StartWorkflow() {
  const {
    runName,
    filesetAsync,
    uploadUrlsAsync,
    uploadAsyncMap,
    startAsync,
    overallSubmitAsync,
  } = useSubmitState();

  const fileMap = useSelectedFileMap();

  return (
    <section
      css={css`
        margin-top: 24px;
      `}
    >
      <form
        css={css`
          display: flex;
          align-items: center;

          > button {
            margin-left: 24px;
          }
        `}
        onSubmit={(event) => {
          event.preventDefault();
          submitWorkflow();
        }}
      >
        <TextField
          id="outlined-basic"
          label="Workflow name"
          variant="outlined"
          value={runName}
          onChange={(event: any) => setRunName(event.target.value)}
          disabled={overallSubmitAsync.status !== 'INIT'}
        />
        {(() => {
          switch (overallSubmitAsync.status) {
            case 'INIT':
              return (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!runName}
                >
                  Start
                </Button>
              );
            case 'PENDING':
            case 'SUCCESS':
            case 'REFRESH':
              return (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={true}
                >
                  Start
                </Button>
              );
            case 'ERROR':
              return (
                <Fragment>
                  <Button type="submit" variant="contained" color="primary">
                    Try again
                  </Button>
                  <Alert severity="error">Something went wrong.</Alert>
                </Fragment>
              );
          }
        })()}
      </form>

      <ul
        css={css`
          margin: 0;
          margin-top: 32px;
          padding: 0;

          list-style: none;

          li {
            margin-top: 8px;
          }
        `}
      >
        <li>
          <AsyncProgress
            status={
              // Compbine two api-calls. Show first unless it's done,
              // then show status of second.
              filesetAsync.status !== 'SUCCESS'
                ? filesetAsync.status
                : uploadUrlsAsync.status
            }
          >
            Prepare upload
          </AsyncProgress>
        </li>
        {Object.entries(fileMap)
          .filter(([key, files]) => !!files)
          .flatMap(([key, files]) =>
            (files as ISystemFile[]).map(
              (file, index) =>
                [key, { file, index }] as [
                  string,
                  { file: ISystemFile; index: number }
                ]
            )
          )
          .map(([key, { file, index }]) => (
            <li key={key + file.name}>
              <AsyncProgress
                status={
                  (
                    uploadAsyncMap[`${key}/${index}/${file.name}`] || {
                      status: 'INIT',
                    }
                  ).status
                }
              >
                Upload {file.name}
              </AsyncProgress>
            </li>
          ))}
        <li>
          <AsyncProgress status={startAsync.status}>
            Start workflow
          </AsyncProgress>
        </li>
      </ul>

      {startAsync.status === 'SUCCESS' &&
        (() => {
          const run = startAsync.data as IRunDetails;

          return (
            <MuiLink
              color="primary"
              component={RouterLink}
              to={`/projects/${run.project_id}/workflows/${run.id}/details`}
              css={css`
                &.MuiLink-root {
                  display: inline-block;
                  margin-top: 64px;
                  float: right;
                }
              `}
            >
              View Workflow Details
            </MuiLink>
          );
        })()}
    </section>
  );
}

function AsyncProgress({
  status,
  children,
}: {
  status: 'INIT' | 'PENDING' | 'ERROR' | 'SUCCESS' | 'REFRESH';
  children: ReactNode;
}) {
  switch (status) {
    case 'INIT':
      return (
        <Alert
          icon={<span />}
          severity="info"
          css={css`
            &.MuiAlert-root {
              color: rgba(0, 0, 0, 0.87);
              background: rgba(33, 33, 33, 0.08);
            }
          `}
        >
          {children}
        </Alert>
      );

    case 'PENDING':
      return (
        <Alert severity="info">
          {children}
          <LinearProgress />
        </Alert>
      );

    case 'ERROR':
      return (
        <Alert
          severity="error"
          css={css`
            .MuiAlert-message {
              flex-grow: 1;
              display: flex;
              justify-content: space-between;
            }
          `}
        >
          {children}
          <span>Failed</span>
        </Alert>
      );

    case 'SUCCESS':
    case 'REFRESH':
      return (
        <Alert
          severity="success"
          css={css`
            .MuiAlert-message {
              flex-grow: 1;
              display: flex;
              justify-content: space-between;
            }
          `}
        >
          {children}
          <span>Done</span>
        </Alert>
      );
  }
}
