import { useState } from 'react';

export type IOrderProperty<Item> = {
  key: keyof Item;
  type: 'string' | 'number';
  getVal: (entry: Item) => any; // value type corresponds to type property
};

export type IOrderState<Item> = IOrderProperty<Item> & {
  direction: 'asc' | 'desc';
};

interface IOrderFnOptions<Item> {
  key: keyof Item;
  direction: 'asc' | 'desc';
  getVal: (entry: Item) => any;
}

function orderNumbersBy<Item>({
  key,
  direction = 'asc',
  getVal,
}: IOrderFnOptions<Item>) {
  return {
    asc: (one: Item, two: Item) => getVal(one) - getVal(two),
    desc: (one: Item, two: Item) => getVal(two) - getVal(one),
  }[direction];
}

function orderStringsBy<Item>({
  key,
  direction = 'asc',
  getVal,
}: IOrderFnOptions<Item>) {
  return {
    asc: (one: Item, two: Item) =>
      getVal(one) < getVal(two) ? -1 : getVal(one) > getVal(two) ? 1 : 0,
    desc: (one: Item, two: Item) =>
      getVal(one) > getVal(two) ? -1 : getVal(one) < getVal(two) ? 1 : 0,
  }[direction];
}

export function orderBy<Item>({
  key,
  direction,
  type,
  getVal,
}: IOrderState<Item>) {
  // eslint-disable default-case
  switch (type) {
    case 'string':
      return orderStringsBy({ key, direction, getVal });
    case 'number':
      return orderNumbersBy({ key, direction, getVal });
  }
}

function toggleOrder<Item>(
  order: void | IOrderState<Item>,
  { key, type, getVal }: IOrderProperty<Item>
): void | IOrderState<Item> {
  const withDirection = (nextDirection: 'asc' | 'desc') => ({
    key,
    direction: nextDirection,
    type,
    getVal,
  });

  if (!order) {
    return withDirection('asc');
  }
  if (order.key !== key) {
    return withDirection('asc');
  }
  switch (order.direction) {
    case 'asc':
      return withDirection('desc');
    case 'desc':
      return void 0; // Go back to no sorting
  }
}

export function indicateOrder<Item>(
  order: void | IOrderState<Item>,
  key: keyof Item
) {
  return (
    order && order.key === key && { 'asc': '▲', 'desc': '▼' }[order.direction]
  );
}

export function useOrder<Item>(): [
  void | IOrderState<Item>,
  (property: IOrderProperty<Item>) => void
] {
  const [order, setOrder] = useState<void | IOrderState<Item>>(void 0);

  function toggleOrderOf(property: IOrderProperty<Item>) {
    setOrder((order: void | IOrderState<Item>) => toggleOrder(order, property));
  }
  return [order, toggleOrderOf];
}
