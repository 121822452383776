import React from 'react';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import ErrorScreen from 'components/ErrorScreen/ErrorScreen';
import NoProjectsScreen from './NoProjectsScreen';
import { Redirect } from 'react-router-dom';

import useProjectsAsync from 'store/useProjectsAsync';

export default function SelectProjectPage() {
  const projectsAsync = useProjectsAsync();

  switch (projectsAsync.status) {
    case 'INIT':
    case 'PENDING':
      return <LoadingScreen />;
    case 'ERROR':
      return <ErrorScreen />;
    case 'SUCCESS':
    case 'REFRESH':
      const projects = projectsAsync.data;

      if (projects.length === 0) {
        return <NoProjectsScreen />;
      } else {
        return <Redirect to={`/projects/${projects[0].id}`} />;
      }
  }
}
