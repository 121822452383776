import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

export default function AlertValid() {
  return (
    <Alert severity="success">
      <AlertTitle>Valid</AlertTitle>
      Provided inputs are valid.
    </Alert>
  );
}
