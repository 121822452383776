import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

if (
  process.env.REACT_APP_ENV === 'prod' ||
  process.env.REACT_APP_ENV === 'test'
) {
  // TODO establish separate sentry env for test and production
  Sentry.init({
    dsn:
      'https://7868dfe5341345e6b9c14c69fef44b1e@o444106.ingest.sentry.io/5418669',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}
