/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';

import { ReactNode } from 'react';

export default function AsyncProgress({
  status,
  children,
}: {
  status: 'INIT' | 'PENDING' | 'ERROR' | 'SUCCESS' | 'REFRESH';
  children: ReactNode;
}) {
  switch (status) {
    case 'INIT':
      return (
        <Alert
          icon={<span />}
          severity="info"
          css={css`
            &.MuiAlert-root {
              color: rgba(0, 0, 0, 0.87);
              background: rgba(33, 33, 33, 0.08);
            }
          `}
        >
          {children}
        </Alert>
      );

    case 'PENDING':
      return (
        <Alert severity="info">
          {children}
          <LinearProgress />
        </Alert>
      );

    case 'ERROR':
      return (
        <Alert
          severity="error"
          css={css`
            .MuiAlert-message {
              flex-grow: 1;
              display: flex;
              justify-content: space-between;
            }
          `}
        >
          {children}
          <span>Failed</span>
        </Alert>
      );

    case 'SUCCESS':
    case 'REFRESH':
      return (
        <Alert
          severity="success"
          css={css`
            .MuiAlert-message {
              flex-grow: 1;
              display: flex;
              justify-content: space-between;
            }
          `}
        >
          {children}
          <span>Done</span>
        </Alert>
      );
  }
}
