import * as Sentry from '@sentry/browser';
import api from 'api';
import produce from 'immer';
import store from 'store';
import getInputsFormData from '../steps/getInputsFormData';
import getSelectedWorkflowDefinitionDetails from '../steps/getSelectedWorkflowDefinitionDetails';
import postAsync from './postAsync';

import getProjectId from 'utils/url/getProjectId';

import IWorkflowDefinitionDetails from 'types/IWorkflowDefinitionDetails';

export default function finallyStartWorkflow() {
  // Assume valid (checked in submitWorkflow)
  const inputs = getInputsFormData(store.getState());
  // Assume non-empty string (checked in submitWorkflow and StartWorkflow)
  const { runName } = store.getState().startWorkflow.submit;
  // Assume set. submitWorkflow runs it before this step.
  const filesetId = store.getState().startWorkflow.submit.filesetAsync!.data!
    .id;
  // Assume selected and fetched
  const detail = getSelectedWorkflowDefinitionDetails(
    store.getState()
  ) as IWorkflowDefinitionDetails;

  return postAsync({
    post() {
      return api
        .post(`projects/${getProjectId()}/workflows`, {
          json: {
            from_definition_id: detail.id,
            dag: detail.dag,
            name: runName,
            inputs: inputs,
            fileset_id: filesetId,
          },
        })
        .json()
        .catch((error) => {
          Sentry.captureException(error);
          throw error;
        });
    },
    getAsync() {
      return store.getState().startWorkflow.submit.startAsync;
    },
    setAsync(async) {
      store.setState(
        produce((state) => {
          state.startWorkflow.submit.startAsync = async;
        })
      );
    },
  });
}
