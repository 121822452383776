import { useEffect, useCallback } from 'react';
import { useStore } from 'store';

import fetchTaskDetails from 'store/fetchTaskDetails';
import getCurrentProjectState from 'store/getCurrentProjectState';

import IAsync from 'types/IAsync';
import ITaskDetails from 'types/ITaskDetails';

const initAsync = { status: 'INIT' };

export default function useAutofetchedTaskDetailsAsync(
  runId: string,
  taskId: string
): IAsync<ITaskDetails> {
  const taskDetailsAsync = useStore(
    useCallback(
      (state) => getCurrentProjectState(state).taskDetailsAsyncMap[taskId],
      [taskId]
    )
  );

  useEffect(() => {
    fetchTaskDetails(runId, taskId);
  }, [runId, taskId]);

  return taskDetailsAsync || initAsync;
}
