import { useEffect } from 'react';
import { useStore } from 'store';

import fetchWorkflowDefinitions from 'store/fetchWorkflowDefinitions';
import getCurrentProjectState from 'store/getCurrentProjectState';

import IAsync from 'types/IAsync';
import IWorkflowDefinition from 'types/IWorkflowDefinition';

export default function useAutofetchedWorkflowDefinitionsAsync(): IAsync<
  IWorkflowDefinition[]
> {
  const workflowDefinitionsAsync = useStore(
    (state) => getCurrentProjectState(state).workflowDefinitionsAsync
  );

  useEffect(() => {
    fetchWorkflowDefinitions();
  }, []);

  return workflowDefinitionsAsync;
}
