import produce from 'immer';
import store from 'store';
import getAreInputsValid from '../steps/getAreInputsValid';
import getAreFilesValid from '../steps/getAreFilesValid';

import createFileset from './createFileset';
import getUploadUrls from './getUploadUrls';
import uploadFiles from './uploadFiles';
import finallyStartWorkflow from './finallyStartWorkflow';

export default async function submitWorkflow() {
  const validInputs = getAreInputsValid(store.getState());
  const validFiles = getAreFilesValid(store.getState());
  const { runName } = store.getState().startWorkflow.submit;

  const readyToSubmit = validInputs && validFiles && runName;

  if (!readyToSubmit) {
    return;
  }

  if (
    ['PENDING', 'SUCCESS'].includes(
      store.getState().startWorkflow.submit.overallSubmitAsync.status
    )
  ) {
    return;
  }

  store.setState(
    produce((state) => {
      state.startWorkflow.submit.overallSubmitAsync = { status: 'PENDING' };
    })
  );

  try {
    await createFileset();
    await getUploadUrls();
    await uploadFiles();
    await finallyStartWorkflow();

    store.setState(
      produce((state) => {
        state.startWorkflow.submit.overallSubmitAsync = { status: 'SUCCESS' };
      })
    );
  } catch (error) {
    store.setState(
      produce((state) => {
        state.startWorkflow.submit.overallSubmitAsync = { status: 'ERROR' };
      })
    );
  }
}
