import React from 'react';
import JsonEditor from '../JsonSchemaFiller/JsonEditor';

import useAreInputsValid from '../pageState/steps/useAreInputsValid';
import useInputsFormData from '../pageState/steps/useInputsFormData';
import setInputsFormData from '../pageState/steps/setInputsFormData';

export default function InputsEditor() {
  const formData = useInputsFormData();
  const formDataValid = useAreInputsValid();

  return (
    <JsonEditor
      formData={formData}
      formDataValid={formDataValid}
      setFormData={setInputsFormData}
    />
  );
}
