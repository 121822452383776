import React from 'react';
import MuiLink from '@material-ui/core/Link';

import IColumnDefinition from '../Table/IColumnDefinition';
import IRunFile from 'types/IRunFile';

const columns: IColumnDefinition<IRunFile>[] = [
  {
    header: 'Filename',
    key: 'filename',
    render: (file) => (
      <MuiLink href={file.url} target="_blank" download={file.filename}>
        {file.filename}
      </MuiLink>
    ),
    getSearchableValue: (file) => file.filename,
    orderProperty: {
      key: 'filename',
      type: 'string',
      getVal: (file) => file.filename,
    },
  },
  {
    header: 'Tag',
    key: 'tag_name',
    getSearchableValue: (file) => file.tag_name,
    orderProperty: {
      key: 'tag_name',
      type: 'string',
      getVal: (file) => file.tag_name,
    },
  },
  {
    header: 'Size',
    key: 'size',
  },
];

export default columns;

export interface IColumnDefinitionWithSearchableValue
  extends IColumnDefinition<IRunFile> {
  getSearchableValue: (file: IRunFile) => any;
}

export const searchableColumns = columns.filter(
  (column) => !!column.getSearchableValue
) as IColumnDefinitionWithSearchableValue[];
