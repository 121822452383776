import { useEffect } from 'react';

import useAccountsAsync from './useAccountsAsync';
import fetchAccounts from './fetchAccounts';

export default function useAutofetchedAccountsAsync() {
  useEffect(() => {
    fetchAccounts();
  }, []);

  return useAccountsAsync();
}
