/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Typography, LinearProgress, Paper } from '@material-ui/core';
import Search from '../Search/Search';
import useSearchState from '../Search/useSearchState';
import EnhancedTable from '../Table/EnhancedTable';
import { useColumns, useSearchableColumns } from './columns';
import DeleteButton from './DeleteButton';
import StartButton from './StartButton';

import useAutofetchedRunsAsync from 'store/useAutofetchedRunsAsync';

import { toggle, unique } from 'utils/array';

import IRunEntry from 'types/IRunEntry';
import { Dispatch, SetStateAction } from 'react';

export default function Runs(props: {
  selectedRunIds: string[];
  setSelectedRunIds: Dispatch<SetStateAction<string[]>>;
}) {
  const { selectedRunIds, setSelectedRunIds } = props;

  const { status, data: runs = [] } = useAutofetchedRunsAsync();

  const columns = useColumns();
  const searchableColumns = useSearchableColumns();

  const {
    searchOptions,
    selectedSearchOptions,
    setSelectedSearchOptions,
    filteredItems: filteredRuns,
  } = useSearchState({
    items: runs,
    searchableColumns,
  });

  return (
    <section>
      <Paper elevation={1} style={{ padding: 48 }}>
        <Typography component="h1" variant="h6">
          Workflows
        </Typography>
        <br />
        {status === 'ERROR' && <p>Something went wrong</p>}
        <Search
          id="search-workflow-runs"
          options={searchOptions}
          selectedOptions={selectedSearchOptions}
          setSelectedOptions={setSelectedSearchOptions}
        />
        <br />
        {['INIT', 'PENDING'].includes(status) && <LinearProgress />}
        <EnhancedTable<IRunEntry>
          columns={columns}
          items={filteredRuns}
          selection={{
            isSelected: (runId: string) => selectedRunIds.includes(runId),
            selectOnly: (runId: string) => setSelectedRunIds([runId]),
            multi: {
              someSelected: filteredRuns.some((run) =>
                selectedRunIds.includes(run.id)
              ),
              allSelected: filteredRuns.every((run) =>
                selectedRunIds.includes(run.id)
              ),
              toggle: (runId: string) => setSelectedRunIds(toggle(runId)),
              selectAll: () =>
                setSelectedRunIds((ids) =>
                  ids.concat(filteredRuns.map((run) => run.id)).filter(unique)
                ),
              deselectAll: () =>
                setSelectedRunIds((ids) =>
                  ids.filter((id) => !filteredRuns.some((run) => run.id === id))
                ),
            },
          }}
          defaultPageSize={25}
        />
        <br />
        <div
          css={css`
            display: flex;

            > * ~ * {
              margin-left: 16px;
            }
          `}
        >
          <DeleteButton
            selectedRuns={runs.filter((run) => selectedRunIds.includes(run.id))}
          />
          <StartButton
            selectedRuns={runs.filter((run) => selectedRunIds.includes(run.id))}
          />
        </div>
      </Paper>
    </section>
  );
}
