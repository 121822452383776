import React, { FunctionComponent } from 'react';
import { CircularProgress } from '@material-ui/core';

import useAutofetchedWorkflowDefinitionDetailsAsync from 'store/useAutofetchedWorkflowDefinitionDetailsAsync';

import IWorkflowDefinitionDetails from 'types/IWorkflowDefinitionDetails';

export default function withWorkflowDefinitionDetails(
  Component: FunctionComponent<{
    workflowDefinitionDetails: IWorkflowDefinitionDetails;
  }>
) {
  return function WorkflowDefinitionDetailsHOC(props: {
    workflowDefinitionId: string;
  }) {
    const detailsAsync = useAutofetchedWorkflowDefinitionDetailsAsync(
      props.workflowDefinitionId
    );

    switch (detailsAsync.status) {
      case 'INIT':
      case 'PENDING':
        return (
          <div>
            <CircularProgress />
          </div>
        );
      case 'ERROR':
        return <div>Failed to fetch workflow definition.</div>;
      case 'SUCCESS':
      case 'REFRESH':
        return <Component workflowDefinitionDetails={detailsAsync.data} />;
    }
  };
}
