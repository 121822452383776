import useAuthStateAsync from 'store/useAuthStateAsync';

import IAuthUser from 'types/IAuthUser';

function useAuthUser(): void | IAuthUser {
  const authStateAsync = useAuthStateAsync();
  return authStateAsync.data && authStateAsync.data.user;
}

export default function useAuthUserFullName(): void | string {
  const authUser = useAuthUser();

  if (!authUser) {
    return void 0;
  }

  const {
    given_name,
    family_name,
  } = authUser.signInUserSession.idToken.payload;

  return `${given_name} ${family_name}`;
}
