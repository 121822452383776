import React from 'react';

import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import useAutofetchedWorkflowDefinitionsAsync from 'store/useAutofetchedWorkflowDefinitionsAsync';

import IWorkflowDefinition from 'types/IWorkflowDefinition';

interface IProps {
  selected: void | IWorkflowDefinition;
  setSelected: (next: void | IWorkflowDefinition) => void;
}

export default function SelectWorkflowDefinition(props: IProps) {
  const defsAsync = useAutofetchedWorkflowDefinitionsAsync();

  switch (defsAsync.status) {
    case 'INIT':
    case 'PENDING':
      return (
        <div>
          <CircularProgress />
        </div>
      );
    case 'ERROR':
      return (
        <div>
          <p>Something went wrong when fetching workflow definitions.</p>
          <p>Please reload the page.</p>
        </div>
      );
    case 'SUCCESS':
    case 'REFRESH':
      const defs = defsAsync.data;

      return (
        <div>
          <Autocomplete
            id="workfow-definition-input"
            style={{ width: 300 }}
            autoHighlight={true /* Press Enter -> select top suggestion */}
            options={defs}
            getOptionLabel={(def: IWorkflowDefinition) => def.name}
            renderInput={(params: any) => (
              <TextField {...params} label="Workflow" variant="outlined" />
            )}
            value={props.selected || null /* null if undefined */}
            onChange={(event: any, newValue: IWorkflowDefinition | null) => {
              props.setSelected(newValue || undefined /* undefined if null */);
            }}
          />
        </div>
      );
  }
}
