import styled from '@emotion/styled';

export const Page = styled.div`
  /*** Layout ***/
  padding: 64px 24px 24px;

  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .wrapper-restrict-width {
    max-width: 1180px;
    margin: auto;
  }

  .wrapper-restrict-width {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  main {
    margin: auto 0;
    padding: 128px 0;
  }

  background: var(--ultraviolet);
  color: #fff;

  *::selection {
    background-color: #555;
    color: var(--mint);
  }

  /*** Internal elements ***/
  header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    img.tailor {
      height: 2.75rem;
    }

    nav {
      margin: 0 76px;
      flex-grow: 1;

      display: flex;
      justify-content: flex-end;
    }
    nav ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      display: flex;

      li + li {
        margin-left: 16px;
      }

      li a {
        text-decoration: underline;
        color: inherit;

        font-size: 0.875rem;
        letter-spacing: 1.25px;
        text-transform: uppercase;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      text-align: center;
      max-width: 970px; /* TODO: Ask Silje if we should really limit width */
    }

    a.request-access {
      margin-top: 64px;
    }

    .features {
      margin-top: 112px;

      display: grid;
      align-items: flex-start;
      grid-template-columns: repeat(4, 1fr);

      column-gap: 150px;
      row-gap: 72px;

      @media (max-width: 1440px) {
        column-gap: 100px;
      }
      @media (max-width: 1080px) {
        column-gap: 64px;
      }
      @media (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 370px) {
        grid-template-columns: 1fr;
      }
    }

    .resources {
      margin-top: 160px;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: stretch;

      grid-gap: 128px;

      .item {
        max-width: 480px;

        padding: 48px;

        background: hsl(261 91% 44% / 1);
        color: white;
        border: 1px solid hsl(261 91% 43% / 1);
        border-radius: 8px;

        > * ~ * {
          margin-top: 1rem;
        }

        a {
          color: inherit;
        }
      }
    }
  }

  footer {
    text-align: center;

    font-size: 0.75rem;
    line-height: 16px;
    letter-spacing: 0.4px;

    a {
      color: inherit;
      text-decoration: none;
    }
  }
`;
