import ky from 'ky';

import { Auth } from 'aws-amplify';

function baseurl() {
  switch (process.env.REACT_APP_ENV) {
    case 'prod':
      return 'https://api.tailor.wf/';
    case 'test':
    case 'arne':
      return 'https://apitest.tailor.wf/';
    case 'dev':
      return 'http://localhost:8000/';
    default:
      throw new TypeError('Unknown value for env,' + process.env.REACT_APP_ENV);
  }
}

const original = ky.create({
  prefixUrl: baseurl(),
});

const extended = original.extend({
  hooks: {
    beforeRequest: [
      (request) => {
        return new Promise((resolve, reject) => {
          Auth.currentSession()
            .then((session) => {
              // @ts-ignore
              const token = session.accessToken.jwtToken;
              request.headers.set('Authorization', `Bearer ${token}`);
              resolve();
            })
            .catch(() => {
              // No logged-in user: don't set auth header
              resolve();
            });
        });
      },
    ],
  },
});

export default extended;
