import { useEffect, useMemo } from 'react';
import { isDefined } from 'utils/array';

type RefCurrent = HTMLElement | null;

export default function useClickOutside(
  currentOrCurrents: RefCurrent | RefCurrent[],
  onClickOutside: (event: Event) => void
) {
  const elements: HTMLElement[] = useMemo(
    () =>
      [
        Array.isArray(currentOrCurrents)
          ? currentOrCurrents
          : [currentOrCurrents],
      ].map((currents) => currents.filter(isDefined))[0],
    [currentOrCurrents]
  );

  useEffect(() => {
    if (elements.length === 0) return;

    const onClick = (event: Event) => {
      const isOutside = elements.every(
        (element) => !element.contains(event.target as HTMLElement)
      );
      if (isOutside) {
        onClickOutside(event);
      }
    };

    document.addEventListener('click', onClick);
    document.addEventListener('contextmenu', onClick);
    return () => {
      document.removeEventListener('click', onClick);
      document.removeEventListener('contextmenu', onClick);
    };
  }, [elements, onClickOutside]);
}
