/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import CaretDown from '@material-ui/icons/ExpandMore';
import CaretUp from '@material-ui/icons/ExpandLess';
import CloseIcon from '@material-ui/icons/Close';
import FileIcon from '@material-ui/icons/Description';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import { useState, useEffect } from 'react';
import usePreventUnload from 'hooks/usePreventUnload';
import useZipFileStates from './useZipFileStates';
import { IZipFileState } from './zipState';

export default function ZipProgresses() {
  const [isOpen, setIsOpen] = useState(true);

  const items: IZipFileState[] = useZipFileStates();

  const pendingCount = items.filter((item) => item.status === 'PENDING').length;
  const doneCount = items.filter((item) => item.status === 'SUCCESS').length;

  usePreventUnload(pendingCount > 0);

  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    setIsDismissed(false);
    setIsOpen(true);
  }, [items.length]);

  if (items.length === 0 || isDismissed) {
    return null;
  }

  return (
    <Paper
      component="aside"
      elevation={2}
      css={css`
        position: fixed;
        bottom: 24px;
        right: 24px;
        width: min(calc(100vw - 32px), 360px);

        overflow: hidden; // Make rounded borders work

        header {
          height: 54px;
          padding-left: 16px;
          padding-right: 4px;

          display: flex;
          justify-content: space-between;
          align-items: center;

          background: #323232;
          color: #fff;

          button.Mui-disabled svg {
            color: #aaa;
          }
        }

        .item {
          padding: 16px 12px;

          display: flex;
          justify-content: space-between;

          .item-heading {
            display: flex;
            align-items: center;

            svg {
              margin-right: 8px;
            }
          }
        }
      `}
    >
      <header>
        <Typography component="h1" variant="body1">
          {pendingCount > 0
            ? `Creating ${pendingCount} ${
                pendingCount === 1 ? 'zipfile' : 'zipfiles'
              }`
            : `Downloaded ${doneCount} ${
                pendingCount === 1 ? 'zipfile' : 'zipfiles'
              }`}
        </Typography>
        <div>
          <IconButton
            color="inherit"
            aria-label="collapse"
            onClick={() => setIsOpen((open) => !open)}
          >
            {isOpen ? <CaretDown /> : <CaretUp />}
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="dismiss"
            disabled={pendingCount > 0}
            onClick={() => setIsDismissed(true)}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </header>
      {isOpen && items.map((item) => <Item key={item.filename} item={item} />)}
    </Paper>
  );
}

function Item({ item }: { item: IZipFileState }) {
  switch (item.status) {
    case 'PENDING':
      return (
        <article className="item">
          <Typography component="h2" variant="body2" className="item-heading">
            <FileIcon color="primary" />
            Zipping ({item.nofFilesDownloaded}/{item.nofFiles} files done)
          </Typography>
          <CircularProgress size={24} style={{ padding: 2 }} />
        </article>
      );
    case 'ERROR':
      return (
        <article className="item">
          <Typography component="h2" variant="body2" className="item-heading">
            <FileIcon color="primary" />
            Zipping failed ({item.nofFilesDownloaded}/{item.nofFiles} files
            done)
          </Typography>
          <ErrorIcon color="secondary" />
        </article>
      );
    case 'SUCCESS':
      return (
        <article className="item">
          <Typography component="h2" variant="body2" className="item-heading">
            <FileIcon color="primary" />
            Zip downloaded ({item.nofFiles} files)
          </Typography>
          <SuccessIcon color="primary" />
        </article>
      );
  }
}
