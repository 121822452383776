import React, { useState } from 'react';
import DownloadButton from './DownloadButton';
import Search from '../Search/Search';
import useSearchState from '../Search/useSearchState';
import EnhancedTable from '../Table/EnhancedTable';

import columns, { searchableColumns } from './columns';

import { toggle, unique } from 'utils/array';

import IRunFile from 'types/IRunFile';

interface IProps {
  files: IRunFile[];
  runId: string;
}

export default function FileTable({ files, runId }: IProps) {
  const {
    searchOptions,
    selectedSearchOptions,
    setSelectedSearchOptions,
    filteredItems: filteredFiles,
  } = useSearchState<IRunFile>({ items: files, searchableColumns });

  const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);

  return (
    <>
      <Search
        id="search-workflow-run-files"
        options={searchOptions}
        selectedOptions={selectedSearchOptions}
        setSelectedOptions={setSelectedSearchOptions}
      />
      <br />
      <EnhancedTable<IRunFile>
        columns={columns}
        items={filteredFiles}
        defaultPageSize={25}
        selection={{
          isSelected: (fileId: string) => selectedFileIds.includes(fileId),
          selectOnly: (fileId: string) => setSelectedFileIds([fileId]),
          multi: {
            someSelected: filteredFiles.some((file) =>
              selectedFileIds.includes(file.id)
            ),
            allSelected: filteredFiles.every((file) =>
              selectedFileIds.includes(file.id)
            ),
            toggle: (fileId: string) => setSelectedFileIds(toggle(fileId)),
            selectAll: () =>
              setSelectedFileIds((ids) =>
                ids.concat(filteredFiles.map((file) => file.id)).filter(unique)
              ),
            deselectAll: () =>
              setSelectedFileIds((ids) =>
                ids.filter(
                  (id) => !filteredFiles.some((file) => file.id === id)
                )
              ),
          },
        }}
      />
      <br />
      <DownloadButton
        runId={runId}
        selectedFiles={files.filter((file) =>
          selectedFileIds.includes(file.id)
        )}
      />
    </>
  );
}
