import produce from 'immer';
import store, { State } from 'store';

export default function setCurrentStepIndex(stepIndex: number) {
  store.setState(
    produce((state: State) => {
      state.startWorkflow.steps.currentStepIndex = stepIndex;
    })
  );
}
