import * as Sentry from '@sentry/browser';
import api from 'api';
import store from 'store';

import getProjectId from 'utils/url/getProjectId';
import getProjectState from 'store/getProjectState';
import setProjectState from 'store/setProjectState';

import { IProjectState } from 'store';
import IAsync from 'types/IAsync';
import IRunEntry from 'types/IRunEntry';

function setRunsAsync(projectId: string, runsAsync: IAsync<IRunEntry[]>) {
  setProjectState(projectId, (state: IProjectState) => {
    return {
      ...state,
      runsAsync,
    };
  });
}

export default function fetchRuns() {
  const projectId = getProjectId();

  const async = getProjectState(store.getState(), projectId).runsAsync;

  switch (async.status) {
    case 'PENDING':
    case 'REFRESH':
      // Already fetching
      return;
    case 'INIT':
    case 'ERROR':
      setRunsAsync(projectId, { status: 'PENDING' });
      break;
    case 'SUCCESS':
      setRunsAsync(projectId, { status: 'REFRESH', data: async.data });
      break;
  }

  api
    .get(`projects/${projectId}/workflows`)
    .json()
    .then(
      (data) => {
        setRunsAsync(projectId, {
          status: 'SUCCESS',
          data: data as IRunEntry[],
        });
      },
      (error) => {
        Sentry.captureException(error);
        setRunsAsync(projectId, { status: 'ERROR' });
      }
    );
}
