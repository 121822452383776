import { useStore, State } from 'store';

import getCurrentProjectState from 'store/getCurrentProjectState';

import fetchUsers from './fetchUsers';

import IUser from 'types/IUser';

const selector = (state: State) => getCurrentProjectState(state).usersAsync;

export default function useUser(userId: string): void | IUser {
  const usersAsync = useStore(selector);

  if (usersAsync.status === 'INIT') {
    fetchUsers();
  }

  return usersAsync.data && usersAsync.data.find((user) => user.id === userId);
}
