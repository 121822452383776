/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import RunState from 'types/RunState';

export default function StateBadge({ state }: { state: RunState }) {
  return (
    <span
      css={css`
        display: inline-block;
        padding: 8px;

        font-size: 21px;
        line-height: 18px;
        letter-spacing: 0.08em;

        color: #fff;

        &.ready {
          background: #2e92f2;
        }
        &.waiting {
          background: #1f62a2;
        }
        &.running {
          background: #f4b90b;
        }
        &.failed {
          background: #db0051;
        }
        &.completed {
          background: #24c75a;
        }
        &.reserved {
          background: #bb8bc1;
        }
        &.archived {
          background: #7f8287;
        }
        &.stopped {
          background: #b7bcc3;
        }
        &.paused {
          background: #ffcfca;
        }
      `}
      className={state.toLowerCase()}
    >
      {state}
    </span>
  );
}
