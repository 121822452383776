import { useMemo, useState } from 'react';

import { uniqueBy } from 'utils/array';

import ISearchOption from './ISearchOption';

export interface ISearchableColumn<IItem> {
  getSearchableValue: (item: IItem) => any;
}

export default function useSearchState<IItem>({
  items,
  searchableColumns,
}: {
  items: IItem[];
  searchableColumns: ISearchableColumn<IItem>[];
}) {
  const [selectedSearchOptions, setSelectedSearchOptions] = useState<
    ISearchOption[]
  >([]);

  const searchOptions: ISearchOption[] = useMemo(() => {
    return items
      .flatMap((item) =>
        searchableColumns
          .map((column): ISearchOption => column.getSearchableValue(item))
          .filter((value) => !!value)
          .map((value) => ({
            value,
            label: value,
          }))
      )
      .filter(uniqueBy((one) => (two) => one.value === two.value));
  }, [items, searchableColumns]);

  const filteredItems = useMemo(() => {
    if (!selectedSearchOptions) {
      return items;
    }
    return items.filter((item) => {
      // Performance opportunity: Store itemPropValues so we don't have to
      // recreate for every item on each render
      const itemPropValues: String[] = searchableColumns
        .map((column): ISearchOption => column.getSearchableValue(item))
        .filter((value) => value !== undefined)
        .map(String);

      return selectedSearchOptions.every((option) =>
        itemPropValues.some((string) =>
          string.toLowerCase().includes(option.value.toLowerCase())
        )
      );
    });
  }, [items, selectedSearchOptions, searchableColumns]);

  return {
    searchOptions,
    selectedSearchOptions,
    setSelectedSearchOptions,
    filteredItems,
  };
}
