import Amplify from 'aws-amplify';
import localhost from './localhost';
import test_tailor_wf from './test.tailor.wf';
import tailor_wf from './tailor.wf';
import localhost_apitest from './localhost-apitest';

switch (process.env.REACT_APP_ENV) {
  case 'prod':
    Amplify.configure(tailor_wf);
    break;
  case 'test':
    Amplify.configure(test_tailor_wf);
    break;
  case 'dev':
    Amplify.configure(localhost);
    break;
  case 'arne':
    Amplify.configure(localhost_apitest);
    break;
}
