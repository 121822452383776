/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import Title from 'components/Title/Title';
import Page from 'components/Page/Page';
import Paper from '@material-ui/core/Paper';
import Steps from './Steps/Steps';
import ReactMarkdown from 'react-markdown';

import useClearStateOnLeaveOnceComplete from './pageState/useClearStateOnLeaveOnceComplete';
import useSelectedWorkflowDefinition from './pageState/steps/useSelectedWorkflowDefinition';

export default function StartWorkflowRunPage() {
  useClearStateOnLeaveOnceComplete();

  const selectedWorkflow = useSelectedWorkflowDefinition();

  return (
    <Fragment>
      <Title>New workflow | Tailor</Title>
      <Page>
        <div
          css={css`
            margin: 48px;

            @media (max-width: 767px) {
              margin: 12px;
            }

            flex-grow: 1;
            display: flex;
            grid-gap: 24px;

            > * {
              flex-basis: 50%;
            }

            @media (max-width: 1080px) {
              flex-direction: column;
              > *:first-of-type {
                min-height: calc(100vh - 200px);
              }
            }
          `}
        >
          <Paper
            css={css`
              padding: 16px 48px;

              display: flex;
              flex-direction: column;
            `}
          >
            <Steps />
          </Paper>
          {selectedWorkflow && (
            <Paper
              css={css`
                position: sticky;
                top: 113px;

                max-height: calc(100vh - 276px);
                overflow: auto;

                padding: 16px 48px;

                img {
                  max-width: 100%;
                }
              `}
            >
              <aside>
                <ReactMarkdown source={selectedWorkflow.description} />
              </aside>
            </Paper>
          )}
        </div>
      </Page>
    </Fragment>
  );
}
