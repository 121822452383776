import produce from 'immer';
import store, { State } from 'store';

import { IFormData } from '../state';

export default function setInputsFormData(formData: IFormData) {
  store.setState(
    produce((state: State) => {
      state.startWorkflow.steps.provideInputs.formData = formData;
    })
  );
}
