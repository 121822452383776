export default function getProjectId(): string {
  const { pathname } = window.location;

  if (!pathname.startsWith('/projects/')) {
    throw Error(`projectId is not in url as pathname=${pathname}`);
  }

  return pathname.split('/')[2];
}

export function getProjectIdIfPresent(): string | void {
  const { pathname } = window.location;

  if (!pathname.startsWith('/projects/')) {
    return void 0;
  }

  return pathname.split('/')[2];
}
