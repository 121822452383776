import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

export default function AlertIncomplete() {
  return (
    <Alert severity="info">
      <AlertTitle>Incomplete</AlertTitle>
      All required files must be provided on the right format.
    </Alert>
  );
}
