import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Theme from './Theme';

import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import LandingPageLoggedOut from 'pages/LandingPageLoggedOut/LandingPageLoggedOut';

import UnavailableProjectPage from 'pages/UnavailableProjectPage/UnavailableProjectPage';
import ErrorScreen from 'components/ErrorScreen/ErrorScreen';

import WorkflowRunsPage from 'pages/WorkflowRunsPage/WorkflowRunsPage';
import WorkflowRunDetailsPage from 'pages/WorkflowRunDetailsPage/WorkflowRunDetailsPage';
import StartWorkflowRunPage from 'pages/StartWorkflowRunPage/StartWorkflowRunPage';
import NewFromRedirect from 'pages/StartWorkflowRunPage/NewFromRedirect';
import SelectProjectPage from 'pages/SelectProjectPage/SelectProjectPage';
import ProfileSettingsPage from 'pages/ProfileSettingsPage/ProfileSettingsPage';

import ZipProgresses from 'shared/ZipProgresses/ZipProgresses';

import useFetchProjects from 'store/useFetchProjects';
import useFetchProfile from 'store/useFetchProfile';
import useProjectsAsync from 'store/useProjectsAsync';
import useTrackAuthState from 'store/useTrackAuthState';
import useAuthStateAsync from 'store/useAuthStateAsync';

export default function App() {
  return (
    <Theme>
      <BrowserRouter>
        <RouterContent />
      </BrowserRouter>

      <ZipProgresses />
    </Theme>
  );
}

function RouterContent() {
  useTrackAuthState();
  const authStateAsync = useAuthStateAsync();

  switch (authStateAsync.status) {
    case 'INIT':
    case 'PENDING':
      return (
        <>
          <LoadingScreen>Authenticating</LoadingScreen>
        </>
      );
    case 'ERROR':
      return (
        <>
          <LandingPageLoggedOut />
          <Redirect to="/" />
        </>
      );
    case 'SUCCESS':
    case 'REFRESH':
      // @ts-ignore
      if (!authStateAsync.data.authenticated) {
        return (
          <>
            <LandingPageLoggedOut />
            <Redirect to="/" />
          </>
        );
      } else {
        return <LoggedInRoutes />;
      }
  }
}

function LoggedInRoutes() {
  useFetchProjects();
  useFetchProfile();

  return (
    <Switch>
      <Route exact path="/" component={SelectProjectPage} />
      <Route exact path="/settings/profile" component={ProfileSettingsPage} />
      <ProjectRoutes />
      <Redirect to="/" />
    </Switch>
  );
}

function ProjectRoutes() {
  const projectsAsync = useProjectsAsync();

  switch (projectsAsync.status) {
    case 'INIT':
    case 'PENDING':
      return <LoadingScreen />;
    case 'ERROR':
      return <ErrorScreen />;
    case 'SUCCESS':
    case 'REFRESH':
      const projects = projectsAsync.data;

      return (
        <Switch>
          {projects.map((project) => (
            <Route
              key={project.id}
              path={`/projects/${project.id}`}
              component={RoutesForProject}
            />
          ))}
          <Route
            path="/projects/:projectId"
            component={UnavailableProjectPage}
          />
        </Switch>
      );
  }
}

function RoutesForProject() {
  return (
    <Switch>
      <Redirect
        exact
        from="/projects/:projectId"
        to="/projects/:projectId/workflows"
      />
      <Route
        exact
        path="/projects/:projectId/workflows"
        component={WorkflowRunsPage}
      />
      <Route
        exact
        path="/projects/:projectId/workflows/:runId/details"
        component={WorkflowRunDetailsPage}
      />
      <Route
        exact
        path="/projects/:projectId/workflows/new"
        component={StartWorkflowRunPage}
      />
      <Route
        exact
        path="/projects/:projectId/workflows/new-from/:runId"
        component={NewFromRedirect}
      />
      <Redirect to="/" />
    </Switch>
  );
}
