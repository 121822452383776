export interface IZipFileState {
  filename: string;
  createdAt: number;
  status: 'PENDING' | 'ERROR' | 'SUCCESS';
  nofFilesDownloaded: number;
  nofFiles: number;
}

export type IZipState = {
  map: Record<string, IZipFileState>;
};

export const initialZipState: IZipState = {
  map: {},
};
