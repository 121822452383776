import * as Sentry from '@sentry/browser';
import api from 'api';
import produce from 'immer';

import getProjectId from 'utils/url/getProjectId';
import setProjectState from 'store/setProjectState';

import { IProjectState } from 'store';

function removeFromState(projectId: string, runId: string) {
  setProjectState(
    projectId,
    produce((state: IProjectState) => {
      if (!state.runsAsync.data) {
        // Not likely to ever happen, as you cannot issue command
        // to delete run before they are fetched.
        return;
      }
      state.runsAsync.data = state.runsAsync.data.filter(
        (run) => run.id !== runId
      );
    })
  );
}

export default function deleteRun(runId: string) {
  const projectId = getProjectId();

  return api
    .delete(`projects/${projectId}/workflows/${runId}`)
    .json()
    .then(
      () => {
        removeFromState(projectId, runId);
      },
      (error) => {
        Sentry.captureException(error);
        throw error;
        // Unlike most actions, this one re-throws!
        // It is because state management is not done here, but outside.
      }
    );
}
