/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import CaretDown from 'icons/CaretDown';
import Popover from 'components/Popover/Popover';
import * as Menu from 'components/MenuPanel/MenuPanel';

import useProjectsAsync from 'store/useProjectsAsync';
import { getProjectIdIfPresent } from 'utils/url/getProjectId';

export default function ProjectDropdown() {
  const projectsAsync = useProjectsAsync();

  const projectId = getProjectIdIfPresent();
  const projectName = (
    (projectsAsync.data || []).find((project) => project.id === projectId) || {}
  ).name;

  return (
    <Popover
      distancePx={8}
      content={({ close }) => (
        <Menu.Panel>
          {projectsAsync.data && (
            <Menu.Section>
              {projectsAsync.data.map((project) => (
                <Menu.ItemNavLink
                  key={project.id}
                  to={`/projects/${project.id}`}
                >
                  {project.name}
                </Menu.ItemNavLink>
              ))}
            </Menu.Section>
          )}
        </Menu.Panel>
      )}
    >
      <button
        css={css`
          padding: 8px;

          display: flex;
          align-items: center;

          svg {
            margin-left: 8px;
          }

          background: none;
          border: none;
          border-radius: 3px;

          font-size: inherit;
          font-family: inherit;
          font-weight: 400;
          line-height: 1.5;
          text-decoration: underline;

          color: #ffffffe3;
          fill: #ffffffe3;
          stroke: #ffffffe3;

          cursor: pointer;

          &:hover {
            background: #fff2;
          }
        `}
      >
        Project: {projectName}
        <CaretDown />
      </button>
    </Popover>
  );
}
