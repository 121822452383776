/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ReactNode } from 'react';

export default function TaskAccordion({
  title,
  children,
}: {
  title: ReactNode;
  children: ReactNode;
}) {
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="task-details-content"
        id="task-details-header"
      >
        <div
          css={css`
            padding: 0 8px 0 12px;
            flex-grow: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <Typography component="h1" variant="h6">
            {title}
          </Typography>
          <Typography
            component="h1"
            variant="h6"
            style={{ fontSize: 10, letterSpacing: 1.5 }}
          >
            TASK
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block' }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}
