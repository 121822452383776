import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ClipboardIcon from '@material-ui/icons/AssignmentSharp';
import ClipboardSuccessIcon from '@material-ui/icons/AssignmentTurnedInSharp';
import ClipboardErrorIcon from '@material-ui/icons/AssignmentLateSharp';

export default function CopyToClipboardButton({ data }: { data: any }) {
  const [status, setStatus] = useState<'INIT' | 'ERROR' | 'SUCCESS'>('INIT');

  useEffect(() => {
    setStatus('INIT');
  }, [data]);

  function doCopy() {
    navigator.clipboard.writeText(data).then(
      () => setStatus('SUCCESS'),
      () => setStatus('ERROR')
    );
  }

  switch (status) {
    case 'INIT':
      return (
        <IconButton title="Copy to clipboard" onClick={doCopy}>
          <ClipboardIcon />
        </IconButton>
      );
    case 'ERROR':
      return (
        <IconButton title="Could not copy to clipbaord" onClick={doCopy}>
          <ClipboardErrorIcon />
        </IconButton>
      );
    case 'SUCCESS':
      return (
        <IconButton title="Copied to clipboard" onClick={doCopy}>
          <ClipboardSuccessIcon />
        </IconButton>
      );
  }
}
