import { useEffect } from 'react';

let instances = 0;

function useLogErrorIfMultipleInstances() {
  useEffect(() => {
    instances++;

    if (instances > 1) {
      console.error(
        Error('There should only be one <Title> in the page at any time.')
      );
    }

    return () => void instances--;
  }, []);
}

function useDifferentTitleWhileMounted(title: string) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title;
    return () => {
      document.title = prevTitle;
    };
  }, [title]);
}

export default function Title({ children }: { children: string }) {
  useLogErrorIfMultipleInstances();
  useDifferentTitleWhileMounted(children);
  return null;
}
