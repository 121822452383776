/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import TaskAccordion from './TaskAccordion';
import CopyToClipboardButton from 'components/CopyToClipboardButton/CopyToClipboardButton';
import ExpandableDetails from '../ExpandableDetails/ExpandableDetails';
import JsonViewer from '../JsonViewer/JsonViewer';

import useAutofetchedTaskDetailsAsync from 'store/useAutofetchedTaskDetailsAsync';

import ITaskDetails from 'types/ITaskDetails';

export default function TaskDetails({
  runId,
  taskId,
}: {
  runId: string;
  taskId: string;
}) {
  const taskDetailsAsync = useAutofetchedTaskDetailsAsync(runId, taskId);

  switch (taskDetailsAsync.status) {
    case 'INIT':
    case 'PENDING':
      return (
        <TaskAccordion title={taskId}>
          <CircularProgress />
        </TaskAccordion>
      );
    case 'ERROR':
      return (
        <TaskAccordion title={taskId}>
          <p>Oops!</p>
          <p>Could not fetch task detail.</p>
        </TaskAccordion>
      );
    case 'SUCCESS':
    case 'REFRESH':
      const taskDetails = taskDetailsAsync.data as ITaskDetails;

      const lastRun = taskDetails.runs && taskDetails.runs.slice(-1)[0];
      const traceback_raw = lastRun && lastRun.traceback_raw;

      return (
        <TaskAccordion title={`${taskDetails.id} ${taskDetails.name}`}>
          {traceback_raw && (
            <ExpandableDetails
              summary="Error"
              contentPlaceholder={traceback_raw}
              renderContent={() => (
                <div
                  css={css`
                    background: #c4c4c41a;
                    padding: 24px;

                    position: relative;
                    button {
                      position: absolute;
                      top: 0;
                      right: 0;
                    }
                  `}
                >
                  {traceback_raw}
                  <CopyToClipboardButton data={traceback_raw} />
                </div>
              )}
            />
          )}
          <ExpandableDetails
            summary="Inputs"
            contentPlaceholder="Inputs as json (only rendered when open)"
            renderContent={() => <JsonViewer json={taskDetails.inputs} />}
          />
          <ExpandableDetails
            summary="Outputs"
            contentPlaceholder="Outputs as json (only rendered when open)"
            renderContent={() => <JsonViewer json={taskDetails.outputs} />}
          />
          <ExpandableDetails
            summary="Files"
            contentPlaceholder="Files as json (only rendered when open)"
            renderContent={() => <JsonViewer json={taskDetails.files} />}
          />
          <ExpandableDetails
            summary="Definition"
            contentPlaceholder="Definition as json (only rendered when open)"
            renderContent={() => <JsonViewer json={taskDetails.definition} />}
          />
        </TaskAccordion>
      );
  }
}
