import React, { useState } from 'react';
import * as S from './Popover.style';
import ClickOutside from 'components/ClickOutside/ClickOutside';

interface ContentProps {
  close: () => void;
}

interface Props {
  content: (x: ContentProps) => React.ReactNode;
  children: React.ReactElement<any>;
  clockPosition?:
    | 'twelve'
    | 'three'
    | 'four'
    | 'five'
    | 'six'
    | 'nine'
    | 'eleven';
  distancePx?: number;
}

export default function Popover(props: Props) {
  const { clockPosition = 'six' } = props;

  const [isOpen, setIsOpen] = useState(false);

  const contentProps: ContentProps = {
    close: () => setIsOpen(false),
  };

  return (
    <ClickOutside onClickOutside={() => setIsOpen(false)}>
      <S.Wrapper>
        {React.cloneElement(props.children, {
          onClick: () => setIsOpen(!isOpen),
          className: [
            props.children.props.className || '',
            isOpen ? 'active' : '',
          ].join(' '),
        })}
        {isOpen && (
          <S.Popover
            className={`${clockPosition}-o-clock`}
            distancePx={props.distancePx || 0}
          >
            {props.content(contentProps)}
          </S.Popover>
        )}
      </S.Wrapper>
    </ClickOutside>
  );
}
