import React from 'react';
import styled from '@emotion/styled';

import cloudSvg from './cloud.svg';
import pythonSvg from './python.svg';
import appSvg from './app.svg';
import databaseSvg from './database.svg';

const Feature = styled.div`
  max-width: 184px;

  text-align: center;

  line-height: 1.5;
  letter-spacing: 0.5px;

  img {
    display: block;
    margin: auto;
    margin-bottom: 32px;
  }
`;

export function Cloud() {
  return (
    <Feature>
      <img alt="cloud" src={cloudSvg} />
      <div>
        Cloud based workflow execution with automatic and secure file handling
      </div>
    </Feature>
  );
}

export function Python() {
  return (
    <Feature>
      <img alt="python" src={pythonSvg} />
      <div>
        Open source python API to define, design and orchestrate workflows{' '}
      </div>
    </Feature>
  );
}

export function App() {
  return (
    <Feature>
      <img alt="app" src={appSvg} />
      <div>
        Monitor, modify and start your workflows through our web interface
      </div>
    </Feature>
  );
}

export function Database() {
  return (
    <Feature>
      <img alt="database" src={databaseSvg} />
      <div>
        Workflows are stored in a database to ensure reproducibility and
        reusability
      </div>
    </Feature>
  );
}
