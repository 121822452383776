import * as Sentry from '@sentry/browser';
import api from 'api';
import store from 'store';
import produce from 'immer';

import getProjectId from 'utils/url/getProjectId';
import getProjectState from 'store/getProjectState';
import setProjectState from 'store/setProjectState';

import { IProjectState } from 'store';
import IAsync from 'types/IAsync';
import IRunFile, { IRunDownloadsResponse } from 'types/IRunFile';

function generateId(idLength: number /* ← Max 9 */) {
  return Math.random()
    .toString(36)
    .slice(2, 2 + idLength);
}

function setRunFilesAsync(
  projectId: string,
  runId: string,
  runFilesAsync: IAsync<IRunFile[]>
) {
  setProjectState(
    projectId,
    produce((state: IProjectState) => {
      state.runFilesAsyncMap[runId] = runFilesAsync;
    })
  );
}

export default function fetchRunFiles(runId: string) {
  const projectId = getProjectId();

  const async = getProjectState(store.getState(), projectId).runFilesAsyncMap[
    runId
  ] || { status: 'INIT' };

  switch (async.status) {
    case 'PENDING':
    case 'REFRESH':
      // Already fetching
      return;
    case 'INIT':
    case 'ERROR':
      setRunFilesAsync(projectId, runId, { status: 'PENDING' });
      break;
    case 'SUCCESS':
      setRunFilesAsync(projectId, runId, {
        status: 'REFRESH',
        data: async.data,
      });
      break;
  }

  api
    .get(`projects/${projectId}/workflows/${runId}/downloads`)
    .json()
    .then(
      (response) => {
        // Map response to a flat list
        const data: IRunFile[] = (response as IRunDownloadsResponse).tags.flatMap(
          (tagGroup) =>
            tagGroup.links.flatMap((fileLink) => ({
              id: generateId(5),
              ...fileLink,
              tag_name: tagGroup.tag_name,
            }))
        );

        setRunFilesAsync(projectId, runId, {
          status: 'SUCCESS',
          data,
        });
      },
      (error) => {
        Sentry.captureException(error);
        setRunFilesAsync(projectId, runId, { status: 'ERROR' });
      }
    );
}
