import React from 'react';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import ErrorScreen from 'components/ErrorScreen/ErrorScreen';
import ProfileSettingsScreen from './ProfileSettingsScreen';

import useProfileAsync from 'store/useProfileAsync';

export default function ProfileSettingsPage() {
  const profileAsync = useProfileAsync();

  switch (profileAsync.status) {
    case 'INIT':
    case 'PENDING':
      return <LoadingScreen />;
    case 'ERROR':
      return <ErrorScreen />;
    case 'SUCCESS':
    case 'REFRESH':
      return <ProfileSettingsScreen profile={profileAsync.data} />;
  }
}
