import React, { FunctionComponent, ReactNode } from 'react';

import SelectWorkflowDefinition from '../SelectWorkflowDefinition/SelectWorkflowDefinition';
import ProvideInputs from '../ProvideInputs/ProvideInputs';
import ProvideFiles from '../ProvideFiles/ProvideFiles';
import StartWorkflow from '../StartWorkflow/StartWorkflow';

import useSelectedWorkflowDefinition from '../pageState/steps/useSelectedWorkflowDefinition';
import setSelectedWorkflowDefinition from '../pageState/steps/setSelectedWorkflowDefinition';
import useAreInputsValid from '../pageState/steps/useAreInputsValid';
import useAreFilesValid from '../pageState/steps/useAreFilesValid';
import useStartAcyncStatus from '../pageState/submit/useStartAcyncStatus';

import IWorkflowDefinition from 'types/IWorkflowDefinition';

export interface IStep {
  title: string;
  canGoTo: boolean;
  isCompleted: boolean;
  Component?: FunctionComponent;
  render?: () => ReactNode;
}

export default function useSteps() {
  const selectedWorkflow = useSelectedWorkflowDefinition();
  const hasSelectedWorkflow = !!selectedWorkflow;
  const inputsValid = useAreInputsValid();
  const filesValid = useAreFilesValid();
  const startAsyncStatus = useStartAcyncStatus();

  // Cannot edit after we begin to submit (even if we fail) – would cause
  // unpredictable results
  const canEdit = startAsyncStatus === 'INIT';

  const steps: IStep[] = [
    {
      title: 'Select workflow',
      canGoTo: true,
      isCompleted: hasSelectedWorkflow,
      render: () => (
        <SelectWorkflowDefinition
          selected={selectedWorkflow}
          setSelected={setSelectedWorkflowDefinition}
        />
      ),
    },
    {
      title: 'Select files',
      canGoTo: hasSelectedWorkflow && canEdit,
      isCompleted: filesValid,
      render: () => (
        <ProvideFiles
          workflowDefinitionId={(selectedWorkflow as IWorkflowDefinition).id}
        />
      ),
    },
    {
      title: 'Provide inputs',
      canGoTo: hasSelectedWorkflow && canEdit,
      isCompleted: inputsValid,
      render: () => (
        <ProvideInputs
          workflowDefinitionId={(selectedWorkflow as IWorkflowDefinition).id}
        />
      ),
    },
    {
      title: 'Start',
      canGoTo: hasSelectedWorkflow && inputsValid && filesValid,
      isCompleted: startAsyncStatus === 'SUCCESS',
      Component: StartWorkflow,
    },
  ];
  return steps;
}
