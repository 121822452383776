import IFileSpec from 'types/IFileSpec';
import ISystemFile from 'types/ISystemFile';

type IsDefined = <T>(value: T | void) => value is T;
type Key = string;
type ErrorMessage = string;
type Map<T> = Record<Key, T>;

export function isAcceptedFileType(fileSpec: IFileSpec) {
  const { ext } = fileSpec;
  return !ext
    ? () => true
    : (file: ISystemFile) =>
        ext.some((ext) =>
          file.name.toLowerCase().endsWith('.' + ext.toLowerCase())
        );
}

export function getErrorMessageMap(
  fileSpecMap: Map<IFileSpec>,
  fileMap: Map<void | ISystemFile[]>
): Map<ErrorMessage> {
  return Object.entries(fileSpecMap)
    .map(([fileKey, fileSpec]): void | [Key, ErrorMessage] => {
      const files = fileMap[fileKey];

      if (fileSpec.required && !files) {
        return [fileKey, 'Required file missing.'];
      }
      if (!files) {
        return void 0; // Ok! No error
      }
      if (!files.every(isAcceptedFileType(fileSpec))) {
        return [
          fileKey,
          `Invalid file type. Must be ${(fileSpec.ext || []).join(',')}.`,
        ];
      }
      return void 0; // Ok! No error
    })
    .filter((Boolean as any) as IsDefined)
    .reduce((map, [key, error]) => {
      map[key] = error;
      return map;
    }, {} as Map<ErrorMessage>);
}

export default function isFileMapValid(
  fileSpecMap: Map<IFileSpec>,
  fileMap: Map<void | ISystemFile[]>
): boolean {
  return Object.keys(getErrorMessageMap(fileSpecMap, fileMap)).length === 0;
}
