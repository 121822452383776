import * as Sentry from '@sentry/browser';
import api from 'api';
import store from 'store';
import produce from 'immer';

import getProjectId from 'utils/url/getProjectId';
import getProjectState from 'store/getProjectState';
import setProjectState from 'store/setProjectState';

import { IProjectState } from 'store';
import IAsync from 'types/IAsync';
import IRunDetails from 'types/IRunDetails';

function setRunDetailsAsync(
  projectId: string,
  runId: string,
  runDetailsAsync: IAsync<IRunDetails>
) {
  setProjectState(
    projectId,
    produce((state: IProjectState) => {
      state.runDetailsAsyncMap[runId] = runDetailsAsync;
    })
  );
}

export default function fetchRunDetails(runId: string) {
  const projectId = getProjectId();

  const async = getProjectState(store.getState(), projectId).runDetailsAsyncMap[
    runId
  ] || { status: 'INIT' };

  switch (async.status) {
    case 'PENDING':
    case 'REFRESH':
      // Already fetching
      return;
    case 'INIT':
    case 'ERROR':
      setRunDetailsAsync(projectId, runId, { status: 'PENDING' });
      break;
    case 'SUCCESS':
      setRunDetailsAsync(projectId, runId, {
        status: 'REFRESH',
        data: async.data,
      });
      break;
  }

  api
    .get(`projects/${projectId}/workflows/${runId}`)
    .json()
    .then(
      (data) => {
        setRunDetailsAsync(projectId, runId, {
          status: 'SUCCESS',
          data: data as IRunDetails,
        });
      },
      (error) => {
        Sentry.captureException(error);
        console.error('failed to fetch /runDetails –', error);
        setRunDetailsAsync(projectId, runId, { status: 'ERROR' });
      }
    );
}
