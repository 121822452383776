import store from 'store';
import produce from 'immer';

import getProjectState from 'store/getProjectState';

import { IProjectState } from 'store';

type GetUpdatedState = (projectState: IProjectState) => IProjectState;

export default function setProjectState(
  projectId: string,
  getUpdatedState: GetUpdatedState
) {
  store.setState(
    produce((state) => {
      state.projectStateMap[projectId] = getUpdatedState(
        getProjectState(state, projectId)
      );
    })
  );
}
