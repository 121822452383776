import { useMemo } from 'react';
import useInputsFormData from './useInputsFormData';
import useInputsSchema from './useInputsSchema';

import validate from '@rjsf/core/lib/validate';

export default function useAreInputsValid() {
  const formData = useInputsFormData();
  const schema = useInputsSchema();

  return useMemo(() => {
    if (!schema) {
      return false;
    }
    const validation = validate(formData, schema);
    return validation.errors.length === 0;
  }, [formData, schema]);
}
