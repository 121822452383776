import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

export default function AlertValidationPending() {
  return (
    <Alert severity="warning">
      <AlertTitle>Invalid JSON</AlertTitle>
      Your JSON is invalid. The latest changes will be discared if you leave
      Editor mode.
    </Alert>
  );
}
