/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import { Route, Link, NavLink, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import tailorLogo from 'logos/tailor.svg';

import ProjectDropdown from './ProjectDropdown';
import UserDropdown from './UserDropdown';

import useAuthStateAsync from 'store/useAuthStateAsync';
import { Auth } from 'aws-amplify';

export default function Header() {
  const authStateAsync = useAuthStateAsync();
  const loggedIn =
    authStateAsync.status === 'SUCCESS' && authStateAsync.data!.authenticated;
  const loggedOut =
    authStateAsync.status === 'SUCCESS' && !authStateAsync.data!.authenticated;

  const { projectId } = useParams();

  return (
    <header
      css={css`
        min-height: 65px;
        padding: 8px 50px;

        display: flex;
        align-items: center;
        justify-content: center; /* Center vertically when it wraps */
        flex-wrap: wrap;

        background-color: var(--indigo);
        color: #fff;

        img.logo {
          width: 72px;
          display: block;
        }

        nav {
          margin: 0 48px;
          flex-grow: 1;
        }
        nav ul {
          margin: 0;
          padding: 0;
          list-style-type: none;

          display: flex;
          align-items: center;

          li + li {
            margin-left: 16px;
          }
          li > a {
            padding: 8px;

            text-decoration: none;
            color: inherit;
          }
          li > a.active {
            text-decoration: underline;
          }
        }
        nav ul li a {
          font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
          font-size: 1rem;
          line-height: 1.5;
          font-weight: 400;
          letter-spacing: 0.00938em;
        }
      `}
    >
      <Link to={projectId ? `/projects/${projectId}` : '/'}>
        <img className="logo" alt="Tailor" src={tailorLogo} />
      </Link>
      <nav>
        <ul>
          <li>
            <ProjectDropdown />
          </li>
          <Route
            path="/projects/:projectId"
            render={({
              match: {
                params: { projectId },
              },
            }) => (
              <Fragment>
                <li>
                  <NavLink exact to={`/projects/${projectId}/workflows`}>
                    Workflows
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to={`/projects/${projectId}/workflows/new`}>
                    New Workflow
                  </NavLink>
                </li>
              </Fragment>
            )}
          />
        </ul>
      </nav>
      {loggedIn && <UserDropdown />}
      {loggedOut && (
        <Button variant="contained" onClick={() => Auth.federatedSignIn()}>
          Log in
        </Button>
      )}
    </header>
  );
}
