import * as Sentry from '@sentry/browser';
import produce from 'immer';
import store from 'store';
import postAsync from './postAsync';

import ISystemFile from 'types/ISystemFile';

export default function uploadFiles() {
  const res: any = store.getState().startWorkflow.submit.uploadUrlsAsync;
  const provideFiles = store.getState().startWorkflow.steps.provideFiles;

  const tags = res.data.tags as Array<{
    tag_name: string;
    links: Array<{
      filename: string;
      url: string;
    }>;
  }>;

  const links = tags.flatMap((tag) => tag.links);

  const promises: Promise<any>[] = Object.entries(provideFiles.fileMap)
    .filter(([key, files]) => !!files)
    .flatMap(([key, files]) =>
      (files as ISystemFile[]).map(
        (file, index) =>
          [key, { file, index: index }] as [
            string,
            { file: ISystemFile; index: number }
          ]
      )
    )
    .map(([key, { file, index }]) => {
      return postAsync({
        post() {
          const link = links.find((link) =>
            link.filename.endsWith(`${key}/${index}/${file.name}`)
          );

          if (!link) {
            const error = Error(
              `No upload url for file /${key}/${index}/${file.name}`
            );
            Sentry.captureException(error);

            // NB: Reject inside postAsync to make the files async status "ERROR",
            // will then be shown in UI
            return Promise.reject(error);
          }

          // Unfortunately we need to conver the file to array buffer. That is
          // done via a listener on a FileReader, so we need to wrap this in a
          // promise and resolve once (1) file is read and (2) we have uploaded.
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = (event: any) => {
              const buffer = event.target.result;

              // The response from this request is not valid json. Thus,
              // response.json() will throw with "Unexpected end of JSON input".
              // Then we might as well use plain fetch instead of ky.
              return fetch(link.url, {
                method: 'PUT',
                body: buffer,
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error(
                      `${response.status}: ${response.statusText}`
                    );
                  }
                  return response;
                })
                .then(resolve, (error) => {
                  Sentry.captureException(error);
                  reject(error);
                });
            };
            fileReader.onerror = reject;
            fileReader.onabort = reject;

            // @ts-ignore – The file is a valid Blob
            fileReader.readAsArrayBuffer(file);
          });
        },
        getAsync() {
          return (
            store.getState().startWorkflow.submit.uploadAsyncMap[
              `${key}/${index}/${file.name}`
            ] || { status: 'INIT' }
          );
        },
        setAsync(async) {
          store.setState(
            produce((state) => {
              state.startWorkflow.submit.uploadAsyncMap[
                `${key}/${index}/${file.name}`
              ] = async;
            })
          );
        },
      });
    });

  return Promise.all(promises);
}
