import { useEffect } from 'react';
import store from 'store';
import { Auth, Hub } from 'aws-amplify';

import { HubCallback } from '@aws-amplify/core/lib/Hub';
import IAsync from 'types/IAsync';
import IAuthUser from 'types/IAuthUser';
import IAuthState from 'types/IAuthState';

function setAuthStateAsync(authStateAsync: IAsync<IAuthState>) {
  store.setState((state) => ({
    ...state,
    authStateAsync,
  }));
}

function setAuthUser(user: void | IAuthUser) {
  setAuthStateAsync({
    status: 'SUCCESS',
    data: user
      ? {
          authenticated: true,
          user,
        }
      : {
          authenticated: false,
          user: void 0,
        },
  });
}

export default function useTrackAuthState() {
  // Ckeck if already authenticated
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setAuthUser(user);
      })
      .catch((error) => {
        setAuthUser(void 0);
      });
  }, []);

  // Listen for change in auth state
  useEffect(() => {
    const onAuthEvent: HubCallback = ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          setAuthUser(data);
          break;
        case 'signOut':
          setAuthUser(void 0);
          break;
      }
    };

    Hub.listen('auth', onAuthEvent);
    return () => Hub.remove('auth', onAuthEvent);
  }, []);
}
