import produce from 'immer';
import setStartWorkflowState from '../setStartWorkflowState';
import { initialStartWorkflowState, IStartWorkflowState } from '../state';

import IWorkflowDefinition from 'types/IWorkflowDefinition';

export default function setSelectedWorkflowDefinition(
  workflowDefinition: void | IWorkflowDefinition
) {
  // Reset the rest of the state when selecting (another) workflowDefinition

  const state = produce((state: IStartWorkflowState) => {
    state.steps.selectWorkflow.selectedWorkflowDefinition = workflowDefinition;
  })(initialStartWorkflowState);

  setStartWorkflowState(state);
}
