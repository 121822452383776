export const supportedRunStates = [
  'READY',
  'WAITING',
  'RUNNING',
  'FAILED',
  'COMPLETED',
  'RESERVED',
  'ARCHIVED',
  'STOPPED',
  'PAUSED',
];

type RunState = typeof supportedRunStates[number];

export default RunState;
