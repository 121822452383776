import IWorkflowDefinition from 'types/IWorkflowDefinition';
import ISystemFile from 'types/ISystemFile';
import IAsync from 'types/IAsync';
import IRunDetails from 'types/IRunDetails';

export type IFormData = Record<any, any>;

export interface IStartWorkflowState {
  steps: {
    currentStepIndex: number;

    selectWorkflow: {
      selectedWorkflowDefinition: void | IWorkflowDefinition;
    };

    provideInputs: {
      formData: IFormData;
    };

    provideFiles: {
      fileMap: Record<string, void | ISystemFile[]>;
    };
  };

  submit: {
    runName: string;
    filesetAsync: IAsync<{ id: string }>;
    uploadUrlsAsync: IAsync<Record<string, string>>;
    uploadAsyncMap: Record<string, IAsync<void>>;
    startAsync: IAsync<IRunDetails>;
    overallSubmitAsync: IAsync<string>;
  };
}

export const initialStartWorkflowState: IStartWorkflowState = {
  steps: {
    currentStepIndex: 0,

    selectWorkflow: {
      selectedWorkflowDefinition: void 0,
    },

    provideInputs: {
      formData: {},
    },

    provideFiles: {
      fileMap: {},
    },
  },

  submit: {
    runName: '',
    filesetAsync: { status: 'INIT' },
    uploadUrlsAsync: { status: 'INIT' },
    uploadAsyncMap: {},
    startAsync: { status: 'INIT' },
    overallSubmitAsync: { status: 'INIT' },
  },
};
