/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import { ReactNode } from 'react';

export default function Page(props: { children: ReactNode }) {
  return (
    <div
      css={css`
        min-height: 100vh;
        display: flex;
        flex-direction: column;

        background-color: #f7f7fb;
      `}
    >
      <Header />
      {props.children}
      <Footer />
    </div>
  );
}
