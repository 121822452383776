import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';
import RunStateLabel from './RunStateLabel';
import UserFullName from 'components/UserFullName/UserFullName';

import useUsers from 'store/useUsers';

import IRunEntry from 'types/IRunEntry';
import IColumnDefinition from '../Table/IColumnDefinition';

import { format } from 'date-fns';
import en from 'date-fns/locale/en-US';

function formatDate(date: string) {
  return format(new Date(date), 'y MMM d, HH:mm', { locale: en });
}

export function useColumns(): IColumnDefinition<IRunEntry>[] {
  const users = useUsers();

  return [
    {
      header: 'Name',
      key: 'name',
      getSearchableValue: (run) => run.name,
      orderProperty: {
        key: 'name',
        type: 'string',
        getVal: (run) => run.name,
      },
    },
    {
      header: 'Date',
      key: 'updated_utc',
      render: (run) => formatDate(run.updated_utc),
      getSearchableValue: (run) => formatDate(run.updated_utc),
      orderProperty: {
        key: 'updated_utc',
        type: 'number',
        getVal: (run) => new Date(run.updated_utc).valueOf(),
      },
    },
    {
      header: 'Id',
      key: 'id',
      getSearchableValue: (run) => run.id,
    },
    {
      header: 'User',
      key: 'user_id',
      render: (run) => <UserFullName id={run.user_id} />,
      getSearchableValue: (run: IRunEntry) => {
        const user = users && users.find((user) => user.id === run.user_id);
        return user && `${user.given_name} ${user.family_name}`;
      },
    },
    {
      header: 'State',
      key: 'state',
      getSearchableValue: (run) => run.state,
      render: (run) => <RunStateLabel state={run.state} />,
    },
    {
      header: '',
      // @ts-ignore – Never mind that there is no "details" property on IRunEntry
      key: 'details',
      render: (run) => (
        <MuiLink
          component={RouterLink}
          to={`/projects/${run.project_id}/workflows/${run.id}/details`}
        >
          Details
        </MuiLink>
      ),
    },
  ];
}

interface IColumnDefinitionWithSearchableValue
  extends IColumnDefinition<IRunEntry> {
  getSearchableValue: (run: IRunEntry) => any;
}

export function useSearchableColumns() {
  return useColumns().filter(
    (column) => !!column.getSearchableValue
  ) as IColumnDefinitionWithSearchableValue[];
}
