// Usage: [1, 1, 2].filter(unique) // [1, 2]
export const unique = <T>(item: T, index: number, array: T[]) =>
  array.indexOf(item) === index;

// usage: filter(uniqueBy(comparator))
export const uniqueBy = <T>(equals: (t: T) => (o: T) => boolean) => (
  item: T,
  index: number,
  array: T[]
) => {
  return array.findIndex(equals(item)) === index;
};

// array.filter(isDefined) – since TS sadly does not understand .filter(Boolean)
export function isDefined<T>(value: T | undefined | null): value is T {
  return value !== undefined && value !== null;
}

/*
 * usage: nextArr = toggle(item)(array) or setArray(toggle(item))
 * - toggle(2)([1, 2]) // => [1]
 * - toggle(2)([1]) // => [1, 2]
 */
export const toggle = <T>(item: T) => (array: T[]): T[] => {
  return array.includes(item)
    ? array.filter((remaining) => remaining !== item)
    : array.concat(item);
};
