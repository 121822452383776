import { State } from 'store';
import getInputsFormData from './getInputsFormData';
import getInputsSchema from './getInputsSchema';

import validate from '@rjsf/core/lib/validate';

export default function getAreInputsValid(state: State) {
  const formData = getInputsFormData(state);
  const schema = getInputsSchema(state);

  if (!schema) {
    return false;
  }
  const validation = validate(formData, schema);
  return validation.errors.length === 0;
}
