import JSZip from 'jszip';
import * as Sentry from '@sentry/browser';

import {
  addZipFileState,
  setZipFileStatus,
  incrementDownloadedCount,
} from './actions';

import IRunFile from 'types/IRunFile';

function resolveBlob(url: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    var httpRequest = new XMLHttpRequest();
    httpRequest.open('GET', url);
    httpRequest.responseType = 'blob'
    httpRequest.onload = function () {
      resolve(this.response);
    };
    httpRequest.onerror = reject;
    httpRequest.send();
  });
}

export default function zipAndDownload(files: IRunFile[], zipFilename: string) {
  var zip = new JSZip();

  function appendBlobToZip(file: IRunFile) {
    return resolveBlob(file.url).then((blob) => {
      zip.file(file.filename, blob);
      incrementDownloadedCount(zipFilename);
    });
  }

  addZipFileState(zipFilename, {
    filename: zipFilename,
    createdAt: Date.now(),
    status: 'PENDING',
    nofFiles: files.length,
    nofFilesDownloaded: 0,
  });

  Promise.all(files.map(appendBlobToZip)).then(() => {
    // All files appended. Create zip blob.
    zip
      .generateAsync({ type: 'blob' })
      .then((blob) => {
        // Save the blob as zip file
        const a = document.createElement('a');
        a.download = zipFilename;
        a.href = URL.createObjectURL(blob);
        a.click();
      })
      .then(() => {
        setZipFileStatus(zipFilename, 'SUCCESS');
      })
      .catch((error) => {
        setZipFileStatus(zipFilename, 'ERROR');
        Sentry.captureException(error);
      });
  });
}
