import * as Sentry from '@sentry/browser';
import api from 'api';
import store from 'store';
import produce from 'immer';

import getProjectId from 'utils/url/getProjectId';
import getProjectState from 'store/getProjectState';
import setProjectState from 'store/setProjectState';

import { IProjectState } from 'store';
import IAsync from 'types/IAsync';
import IWorkflowDefinitionDetails from 'types/IWorkflowDefinitionDetails';

function setWorkflowDefinitionDetailsAsync(
  projectId: string,
  workflowDefinitionId: string,
  workflowDefinitionDetailsAsync: IAsync<IWorkflowDefinitionDetails>
) {
  setProjectState(
    projectId,
    produce((state: IProjectState) => {
      state.workflowDefinitionDetailsAsyncMap[
        workflowDefinitionId
      ] = workflowDefinitionDetailsAsync;
    })
  );
}

export default function fetchWorkflowDefinitionDetails(
  workflowDefinitionId: string
) {
  const projectId = getProjectId();

  const async = getProjectState(store.getState(), projectId)
    .workflowDefinitionDetailsAsyncMap[workflowDefinitionId] || {
    status: 'INIT',
  };

  switch (async.status) {
    case 'PENDING':
    case 'REFRESH':
      // Already fetching
      return;
    case 'INIT':
    case 'ERROR':
      setWorkflowDefinitionDetailsAsync(projectId, workflowDefinitionId, {
        status: 'PENDING',
      });
      break;
    case 'SUCCESS':
      // Only fetch this once
      return;
  }

  api
    .get(`projects/${projectId}/workflow_definitions/${workflowDefinitionId}`)
    .json()

    .then(
      (data) => {
        setWorkflowDefinitionDetailsAsync(projectId, workflowDefinitionId, {
          status: 'SUCCESS',
          data: data as IWorkflowDefinitionDetails,
        });
      },
      (error) => {
        Sentry.captureException(error);
        setWorkflowDefinitionDetailsAsync(projectId, workflowDefinitionId, {
          status: 'ERROR',
        });
      }
    );
}
