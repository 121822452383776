import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

export default function AlertValidationPending() {
  return (
    <Alert severity="info">
      <AlertTitle>Pending</AlertTitle>
      Your data is not saved or validated.
    </Alert>
  );
}
