import produce from 'immer';
import store, { State } from 'store';

import ISystemFile from 'types/ISystemFile';

export default function setSelectedFiles(
  fileKey: string,
  filesForKey: void | ISystemFile[]
) {
  store.setState(
    produce((state: State) => {
      state.startWorkflow.steps.provideFiles.fileMap[fileKey] = filesForKey;
    })
  );
}
