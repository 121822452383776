/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import CopyToClipboardButton from 'components/CopyToClipboardButton/CopyToClipboardButton';

import IAsync from 'types/IAsync';

export default function TokenAsync({
  tokenAsync,
}: {
  tokenAsync: IAsync<string>;
}) {
  switch (tokenAsync.status) {
    case 'INIT':
      return (
        <Alert
          icon={<span />}
          severity="info"
          css={css`
            &.MuiAlert-root {
              color: rgba(0, 0, 0, 0.87);
              background: rgba(33, 33, 33, 0.08);
            }
          `}
        >
          ************************************
        </Alert>
      );

    case 'PENDING':
      return (
        <Alert severity="info">
          ************************************
          <LinearProgress />
        </Alert>
      );

    case 'ERROR':
      return (
        <Alert
          severity="error"
          css={css`
            .MuiAlert-message {
              flex-grow: 1;
              display: flex;
              justify-content: space-between;
            }
          `}
        >
          ************************************
          <span>Failed</span>
        </Alert>
      );

    case 'SUCCESS':
    case 'REFRESH':
      return (
        <Alert
          severity="success"
          css={css`
            &.MuiAlert-root {
              align-items: center;
            }
            .MuiAlert-message {
              flex-grow: 1;
              display: flex;
              align-items: center;
            }
          `}
        >
          <span>{tokenAsync.data}</span>
          <CopyToClipboardButton data={tokenAsync.data} />
        </Alert>
      );
  }
}
