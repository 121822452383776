import { State } from 'store';
import getFileMap from './getFileMap';
import getFilesSchema from './getFilesSchema';

import isFileMapValid from './isFileMapValid';

export default function getAreInputsValid(state: State) {
  const fileMap = getFileMap(state);
  const schema = getFilesSchema(state);

  if (!schema) {
    return false;
  }
  return isFileMapValid(schema, fileMap);
}
