import React from 'react';
import JsonForm from '../JsonSchemaFiller/JsonForm';

import useAreInputsValid from '../pageState/steps/useAreInputsValid';
import useInputsFormData from '../pageState/steps/useInputsFormData';
import setInputsFormData from '../pageState/steps/setInputsFormData';

import IWorkflowDefinitionDetails from 'types/IWorkflowDefinitionDetails';

interface IProps {
  workflowDefinitionDetails: IWorkflowDefinitionDetails;
}

export default function InputsForm(props: IProps) {
  const schema = props.workflowDefinitionDetails.inputs_schema.inputs || {};

  const formData = useInputsFormData();
  const formDataValid = useAreInputsValid();

  return (
    <JsonForm
      schema={schema}
      formData={formData}
      formDataValid={formDataValid}
      setFormData={setInputsFormData}
    />
  );
}
