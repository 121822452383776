/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import {
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ClearIcon from '@material-ui/icons/Clear';
import AlertIncomplete from './AlertIncomplete';
import AlertValid from './AlertValid';

import withWorkflowDefinitionDetails from '../withWorkflowDefinitionDetails';

import { isAcceptedFileType } from '../pageState/steps/isFileMapValid';
import setSelectedFiles from '../pageState/steps/setSelectedFiles';
import useSelectedFileMap from '../pageState/steps/useSelectedFileMap';
import useAreFilesValid from '../pageState/steps/useAreFilesValid';

import IWorkflowDefinitionDetails from 'types/IWorkflowDefinitionDetails';
import IFileSpec from 'types/IFileSpec';
import ISystemFile from 'types/ISystemFile';

interface IProps {
  workflowDefinitionDetails: IWorkflowDefinitionDetails;
}

function ProvideFiles(props: IProps) {
  const fileSpecMap = props.workflowDefinitionDetails.files_schema.in;
  const selectedFileMap = useSelectedFileMap();
  const valid = useAreFilesValid();

  return (
    <Box m={1}>
      {valid ? <AlertValid /> : <AlertIncomplete />}
      <div style={{ height: '16px' }} />

      <div
        css={css`
          > * {
            margin-top: 36px;
          }
          > *:not(:first-of-type) {
            margin-top: 64px;
          }
        `}
      >
        {Object.entries(fileSpecMap).map(([key, fileSpec]) => (
          <FileEntry
            key={key}
            fileKey={key}
            fileSpec={fileSpec}
            files={selectedFileMap[key]}
            setFiles={(files: void | ISystemFile[]) =>
              setSelectedFiles(key, files)
            }
          />
        ))}
      </div>
    </Box>
  );
}

export default withWorkflowDefinitionDetails(ProvideFiles);

function FileEntry(props: {
  fileKey: string;
  fileSpec: IFileSpec;
  files: void | ISystemFile[];
  setFiles: (files: void | ISystemFile[]) => void;
}) {
  const { files = [], fileSpec } = props;

  function removeFile(file: ISystemFile) {
    props.setFiles(files.filter((remaining) => remaining !== file));
  }

  return (
    <div>
      <Box mb={1} mt={1}>
        <Typography component="h3" variant="h5">
          {fileSpec.title || props.fileKey}
          {fileSpec.required && ' *'}
        </Typography>
        <Divider />
      </Box>
      <Typography
        component="p"
        variant="subtitle2"
        style={{ marginTop: '5px' }}
      >
        {fileSpec.description}
      </Typography>

      <div style={{ height: '16px' }} />

      <Box mt={1} mb={1}>
        <Button
          component="label"
          variant="outlined"
          color="primary"
          startIcon={<AttachFileIcon />}
        >
          {fileSpec.multiple ? 'Select file(s)' : 'Select file'}
          <input
            key={Math.random()}
            style={{ display: 'none' }}
            type="file"
            accept={
              fileSpec.ext && fileSpec.ext.map((ext) => `.${ext}`).join(',')
            }
            multiple={fileSpec.multiple}
            onChange={(e: any) => {
              const { files } = e.target;
              props.setFiles(files.length === 0 ? void 0 : Array.from(files));
            }}
          />
        </Button>

        <div style={{ height: '12px' }} />

        {files.length === 0 && fileSpec.required && (
          <Alert severity="error">No file selected</Alert>
        )}
        <div>
          <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
            {files.map((file) => {
              if (isAcceptedFileType(fileSpec)(file)) {
                return (
                  <li key={file.name} style={{ margin: '4px 0' }}>
                    <Alert
                      severity="success"
                      css={css`
                        .MuiAlert-message {
                          flex-grow: 1;
                          display: flex;
                          justify-content: space-between;
                        }
                        .MuiIconButton-root {
                          padding: 12px;
                          margin: -12px;
                        }
                      `}
                    >
                      {file.name}
                      <IconButton
                        onClick={() => removeFile(file)}
                        style={{ margin: -12 }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </Alert>
                  </li>
                );
              } else {
                return (
                  <li key={file.name} style={{ margin: '4px 0' }}>
                    <Alert
                      severity="error"
                      css={css`
                        .MuiAlert-message {
                          flex-grow: 1;
                        }
                        .MuiAlert-message > .MuiAlertTitle-root {
                          flex-grow: 1;
                          display: flex;
                          justify-content: space-between;

                          font-size: inherit;
                          font-weight: inherit;
                        }
                        .MuiIconButton-root {
                          padding: 12px;
                          margin: -12px;
                        }
                      `}
                    >
                      <AlertTitle>
                        {file.name}
                        <IconButton onClick={() => removeFile(file)}>
                          <ClearIcon />
                        </IconButton>
                      </AlertTitle>
                      <em>
                        Invalid file type. Must be:{' '}
                        {(fileSpec.ext || []).join(',')}
                      </em>
                    </Alert>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </Box>
    </div>
  );
}
