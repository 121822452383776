import { useStore, State } from 'store';

function getMap(state: State) {
  return state.zipState.map;
}

export default function useZipFileStates() {
  // Do Object.values outside selector to enable memoization
  return Object.values(useStore(getMap)).sort(
    (a, b) => b.createdAt - a.createdAt
  );
}
