import * as Sentry from '@sentry/browser';
import api from 'api';
import store from 'store';

import getProjectId from 'utils/url/getProjectId';
import getProjectState from 'store/getProjectState';
import setProjectState from 'store/setProjectState';

import { IProjectState } from 'store';
import IAsync from 'types/IAsync';
import IWorkflowDefinition from 'types/IWorkflowDefinition';

function setWorkflowDefinitionsAsync(
  projectId: string,
  workflowDefinitionsAsync: IAsync<IWorkflowDefinition[]>
) {
  setProjectState(projectId, (state: IProjectState) => {
    return {
      ...state,
      workflowDefinitionsAsync,
    };
  });
}

export default function fetchWorkflowDefinitions() {
  const projectId = getProjectId();

  const async = getProjectState(store.getState(), projectId)
    .workflowDefinitionsAsync;

  switch (async.status) {
    case 'PENDING':
    case 'REFRESH':
      // Already fetching
      return;
    case 'INIT':
    case 'ERROR':
      setWorkflowDefinitionsAsync(projectId, { status: 'PENDING' });
      break;
    case 'SUCCESS':
      // Only fetch this once
      return;
  }

  api
    .get(`projects/${projectId}/workflow_definitions`)
    .json()
    .then(
      (data) => {
        setWorkflowDefinitionsAsync(projectId, {
          status: 'SUCCESS',
          data: data as IWorkflowDefinition[],
        });
      },
      (error) => {
        Sentry.captureException(error);
        setWorkflowDefinitionsAsync(projectId, { status: 'ERROR' });
      }
    );
}
