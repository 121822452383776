/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import Title from 'components/Title/Title';
import Page from 'components/Page/Page';
import Runs from './Runs/Runs';
import Files from './Files/Files';

import { useState } from 'react';

export default function WorkflowRunsPage() {
  const [selectedRunIds, setSelectedRunIds] = useState<string[]>([]);

  return (
    <Fragment>
      <Title>Workflows | Tailor</Title>
      <Page>
        <div
          css={css`
            margin: 48px;

            @media (max-width: 767px) {
              margin: 12px;
            }

            display: flex;
            grid-gap: 24px;

            > *:first-of-type {
              flex-basis: 60%;
            }
            > *:nth-of-type(2) {
              flex-basis: 40%;
              width: 0; /* Respect flex-basis */
            }

            @media (max-width: 1536px) {
              flex-direction: column;
              > *:nth-of-type(2) {
                width: unset;
              }
            }
          `}
        >
          <Runs
            selectedRunIds={selectedRunIds}
            setSelectedRunIds={setSelectedRunIds}
          />
          <Files runIds={selectedRunIds} />
        </div>
      </Page>
    </Fragment>
  );
}
