import { useEffect } from 'react';
import useStartAcyncStatus from './submit/useStartAcyncStatus';
import clearState from './clearState';

export default function useClearStateOnLeaveOnceComplete() {
  const status = useStartAcyncStatus();

  useEffect(() => {
    if (status === 'SUCCESS') {
      return () => clearState();
    }
  }, [status]);
}
