import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { ControlledEditor as Editor } from '@monaco-editor/react';
import AlertValid from './AlertValid';
import AlertIncomplete from './AlertIncomplete';
import AlertValidationPending from './AlertValidationPending';
import AlertInvalidJson from './AlertInvalidJson';

import debounce from 'lodash.debounce';

interface IProps {
  formData: Record<any, any>;
  formDataValid: boolean;
  setFormData: (formData: Record<any, any>) => void;
}

export default function JsonEditor(props: IProps) {
  const { formData, formDataValid, setFormData } = props;

  const initValue = useMemo(() => JSON.stringify(formData, null, 2), [
    formData,
  ]);

  const [validationStatus, setValidationStatus] = useState<
    'PENDING' | 'NOT_VALIDATED' | 'VALIDATED'
  >('VALIDATED');

  const onValueChanged = useCallback(
    (value: string) => {
      try {
        const formData = JSON.parse(value);
        setValidationStatus('VALIDATED');
        setFormData(formData);
      } catch (error) {
        setValidationStatus('NOT_VALIDATED');
        console.error('Could not parse:', error);
      }
    },
    [setFormData]
  );
  const debouncedOnValueChange = useMemo(() => debounce(onValueChanged, 300), [
    onValueChanged,
  ]);

  const getValueRef = useRef<() => string>(null);
  useEffect(() => {
    return () => {
      const getValue = getValueRef.current;
      if (!getValue) {
        return;
      }
      const value = getValue();
      onValueChanged(value);
    };
  }, [onValueChanged]);

  return (
    <>
      {(() => {
        switch (validationStatus) {
          case 'PENDING':
            return <AlertValidationPending />;
          case 'NOT_VALIDATED':
            return <AlertInvalidJson />;
          case 'VALIDATED':
            return formDataValid ? <AlertValid /> : <AlertIncomplete />;
        }
      })()}

      <div style={{ height: '48px' }} />

      <Editor
        height="400px"
        theme="vs-light"
        language="json"
        options={{
          formatOnType: true,
          formatOnPaste: true,
        }}
        value={initValue}
        onChange={(_, value: string | void) => {
          setValidationStatus('PENDING');
          debouncedOnValueChange(value || '');
        }}
        loading={'Loading...'}
        editorDidMount={(getValue: () => string) => {
          // @ts-ignore – Why on earth is ref.current readonly?
          getValueRef.current = getValue;
        }}
      />
    </>
  );
}
