import { useEffect, useCallback } from 'react';
import { useStore } from 'store';

import fetchRunDetails from 'store/fetchRunDetails';
import getCurrentProjectState from 'store/getCurrentProjectState';

import IAsync from 'types/IAsync';
import IRunDetails from 'types/IRunDetails';

const initAsync = { status: 'INIT' };

export default function useAutofetchedRunDetailsAsync(
  runId: string
): IAsync<IRunDetails> {
  const runDetailsAsync = useStore(
    useCallback(
      (state) => getCurrentProjectState(state).runDetailsAsyncMap[runId],
      [runId]
    )
  );

  useEffect(() => {
    fetchRunDetails(runId);
  }, [runId]);

  return runDetailsAsync || initAsync;
}
